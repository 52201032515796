import { useState } from "react";
import { ADMIN_COMMUNITY_ID, IMAGE_URL, ROLE, TRANSLATED_LANG } from "../../../common_utils/constants";
import CommunityModal from "../../component/CommunityModal";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import { MemberProvider, useAdminMembersContext } from "../../contexts_admin/member_context";
import { useEffect } from "react";
import StylishLabel from "../../component/StylishLabel";
import RotatingHeart from "../../../common_utils/loading_animator";
import { Button, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography } from "@mui/material";
import { createTheme } from "@mui/system";
import { useTheme } from "@emotion/react";
import { Navigate, useNavigate  } from "react-router-dom";
import React from "react";
import ImportExcel from "./import_excel";








function FreeMembers(){

    const {freeMembers ,  data_free_members , loading_freemmebers , clearlist_member_details_own_admin , clear_featuredprofiles} = useAdminMembersContext();
    const [load, setLoad] = useState(false);
    const [openCommunity , setopenCommunity] = useState(false);
    const [communityId, setCommunityId] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const navigate = useNavigate();


    const theme = useTheme({
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              '& $notchedOutline': {
                borderColor: 'red', // Change this to your desired outline color
              },
              '&:hover $notchedOutline': {
                borderColor: '#FFC0CB', // Change this to your desired outline color on hover
              },
            },
          },
        },
      },
    });


    useEffect(()=>{

      localStorage.setItem(TRANSLATED_LANG , 'en');

        if(data_free_members.length > 0 || loading_freemmebers == false){

          setLoad(true);

        }else{


          let communityId_admin  =  "0";
          if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

            communityId_admin = localStorage.getItem("communityId_admin");
            setCommunityId(communityId_admin);

          }else{
           communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
            setCommunityId(communityId_admin);
          }

            freeMembers({
                "communityId":communityId_admin,
                "offset":"0",
                "query":""  
              });

        }
       
    },[data_free_members]);


    const handleClickCommunity = () => {
        setopenCommunity(true);
      }
  
  
    const handleCloseCommunity = (isopen) => {
        setopenCommunity(isopen);
      }
  
  
      const handleClickCommunityLink = (communityId_admin) => {
  
        setCommunityId(communityId_admin);
        localStorage.setItem("communityId_admin" , communityId_admin);
  
        freeMembers({
            "communityId":communityId_admin,
            "offset":"0",
            "query":""  
          });
  
      }



      const handlePageChange = (event, value) => {
    
        setCurrentPage(value);
    
        freeMembers({
            "communityId":communityId,
            "offset": value == 1 ? 0 : (((value - 2) * 10) + 10),
            "query":query  
          });
  
    }
  
    const [query, setValue] = useState('');

    const handleChange = (event) => {
      setValue(event.target.value);

      freeMembers({
        "communityId":communityId,
        "offset": "0",
        "query":event.target.value  
      });

    };




    const handleUserdetailsViewClick = (userId , username , profile_id) => {

      const about_user = {
        userId:userId ,
        communityId: communityId,
        username: username,
        profile_id: profile_id
      };

      

      clearlist_member_details_own_admin();
      clear_featuredprofiles();
      
      navigate(`/user_details_byid?userId=${about_user.userId}&communityId=${communityId}&username=${about_user.username}&profile_id=${about_user.profile_id}`);

    }



    const [openModal, setOpenModal] = useState(false);

    const handleImport  = () => {
      setOpenModal(true);
    }

    const handleCloseModal = () => {
         setOpenModal(false);
    }

      
      let role = localStorage.getItem(ROLE);

    return (<>
    
   { load == false ?  <RotatingHeart/> : 
       
       <div  style={{backgroundColor:"#FAFBFD"}}>

        { role == "developer" ? <StylishLabel  text={"Select Community"}  clickSelectCommunity={handleClickCommunity} /> : ""}

        <Grid container spacing={2} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "165px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingRight:"15px"   }}>

<Grid key={5} item xs={7}>
  <ThemeProvider theme={theme}>
  <TextField
      label="Enter text to search"
      value={query}
      onChange={handleChange}
      variant="outlined"

      fullWidth
    />
    </ThemeProvider>
    </Grid>

    <Grid item xs={2.5}>
    <Button type="button" style={{
                                justifyContent: "center", alignContent: "center",
                                background: "#E3256B", width: "100%", color: "white", fontWeight: "bold"
                            }} onClick={handleImport}>Import From Excel</Button>
    </Grid>


    <Grid item xs={2.5}>
    <Button type="button" style={{
                                justifyContent: "center", alignContent: "center",
                                background: "#E3256B", width: "100%", color: "white", fontWeight: "bold"
                            }} onClick={handleImport}>Exort To Excel</Button>
    </Grid>
    <Grid key={6} item xs={12} spacing={0} style={{marginBottom:"10px"}}>
<TableContainer component={Paper}>
  <Table>
    <TableHead>
      <Typography  variant="h6" align="left" width="200px" style={{paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb"}}>Free Members</Typography>
      <TableRow style={{width:"100%"}}>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Profile Picture</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Name</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>ProfileId</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Gender</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>EmailID</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Birthdate</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Mobile</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>View Details</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {data_free_members.length > 0 && data_free_members[0].map((free_members, index) => (
        <TableRow key={free_members.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
          <TableCell>
          {((currentPage-1)*10)+(index+1)+".        "} <img src={IMAGE_URL+"uploads/"+free_members.pic1} alt="No Image" style={{ width: 50, height: 50 }} />
          </TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.name+" "+free_members.surname}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.profile_id}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.gender}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.emailid}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px' }}>{free_members.birthdate}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px' }}>{free_members.mobile}</TableCell>
          <TableCell><span
      style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }} onClick={() => handleUserdetailsViewClick(free_members.Id , free_members.name+" "+free_members.surname , free_members.profile_id)}>
      {"Click to View"}
    </span></TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
            <Pagination
                count={data_free_members.length > 0 && Math.ceil(data_free_members[1][0]["total_row_count"] / 10)}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                size="medium"
            />
        </div>


</Grid>



        </Grid>
    
        {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}

        {openModal == true ?  <MemberProvider><ImportExcel open={true} handleClose={handleCloseModal}  /></MemberProvider> : ""}
    
    </div>
   }
    </>);


}

export default FreeMembers;