import { Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useAboutCommunityContextAdmin } from "../../contexts_admin/aboutcommunity_context";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import InputMask from 'react-input-mask';






function AddStartDate(){


    const { insert_app_blockreason , data_add_app_blockreason , update_app_blockreason  , data_update_app_blockreason   , loading_about_community} = useAboutCommunityContextAdmin();
    const [date, setDate] = useState('');
    const [reason , setReason] = useState("");

    const { communityId } = useParams();


    const location = useLocation();

    useEffect(()=>{

        const details = location.state?.myObject;

        

        if(details != undefined){

            setDate(details.key.start_date);
            setReason(details.key.reason);
      
        }
   

    },[]);



    const navigate = useNavigate();
    useEffect(()=>{

        if(data_add_app_blockreason.affectedRows >= 1 || data_update_app_blockreason.affectedRows >= 1){
        
            navigate("/list_startdate");
            window.location.reload();
    
           }

    },[data_add_app_blockreason , data_update_app_blockreason]);



    const handleChange = (e) => {
        setDate(e.target.value);
      };
    

    const handlesetReason = (event) => {
        setReason(event.target.value);
    };

    

    const add_startdate_click = () => {


        if(location.state?.myObject == undefined){

       insert_app_blockreason({
        "start_date": date,
        "reason": reason,
        "communityId": communityId
       });

       }else{


        update_app_blockreason({
            "start_date": date,
            "reason": reason,
            "Id": location.state?.myObject.key.Id
        });

       }

    }


    return (<div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: "100px", left:"45px" , width: "90%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , backgroundColor:"#F8F8F8"}}>


        <Grid container spacing={2}>
                
                <Grid item xs={12} style={{marginTop:"20px"}}>
                <h2>Masked Date Input</h2>
      <InputMask
        mask="99/99/9999"
        value={date}
        onChange={handleChange}
        placeholder="DD/MM/YYYY"
      >
        {(inputProps) => <input {...inputProps} />}
      </InputMask>
                </Grid>

                <Grid item xs={12} style={{marginTop:"10px"}}>
                  <TextField
                    label="Enter Reason to Stop App"
                    value={reason}
                    onChange={handlesetReason}
                    fullWidth
                  />
                </Grid>
             
                <Grid item xs={12} alignContent={"center"} alignItems={"center"}>
                   <Button variant="contained" style={{ backgroundColor: '#d74894', borderRadius: '20px' , marginTop:"15px" , marginBottom:"15px" , fontWeight:"bold" , fontSize:"16px" , justifyContent:"center" , width:"100%"}} onClick={add_startdate_click} >Save Details</Button>
               </Grid>
        
                
                </Grid>
                
                </div>);




}

export default AddStartDate;