import { Grid, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import '../../../styles/timelineother.css';
import { useTranslation } from "react-i18next";
import SectionBox from "../../../pages/user_details/SectionBox";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import RotatingHeart from "../../../common_utils/loading_animator";
import { useAdminMembersContext } from "../../contexts_admin/member_context";
import React from "react";




function UserDetailsById(){


    const { t } = useTranslation();
    const { MemberDetailsListOWnAdmin , list_member_details_own_admin , loading_own_details_admin ,verify_user_profile , insert_featured_profile , delete_featured_profile , verify_user_profile_list ,insert_featured_profile_list , delete_featured_profile_list , clear_featuredprofiles} = useAdminMembersContext();

    const [load , setLoad] = useState(false);

    const [userId , setUserId] = useState("");
    const [communityId , setCommunityId] = useState("");
    const [username , setUsername] = useState("");

    
    const [selectedOption3, setSelectedOption3] = useState(null);

    // Function to handle option change
    const handleOptionChange3 = (event) => {
        setSelectedOption3(event.target.value);
    };
   

    const navigate = useNavigate();  
    const location = useLocation();
 
    

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        const userId = searchParams.get('userId');
        const communityId = searchParams.get('communityId');
        const username = searchParams.get('username');

      

        setUserId(userId);
        setCommunityId(communityId);
        setUsername(username);
      

      }, [location]);


      useEffect(()=>{


        const searchParams = new URLSearchParams(location.search);
        const userId = searchParams.get('userId');
        const communityId = searchParams.get('communityId');
       
        if(list_member_details_own_admin.length > 0){

            setLoad(true);


            console.log(list_member_details_own_admin[15][0]);

            if(list_member_details_own_admin[15][0]["0"]["user_verify"] == "1"){
              setSelectedOption3("accept");
            }else if(list_member_details_own_admin[15][0]["0"]["user_verify"] == "2"){
               setSelectedOption3("reject");
            }

            if(list_member_details_own_admin[15][0]["0"]["featured_profile"] == 1){
                setIsChecked(true);
            }else if(list_member_details_own_admin[15][0]["0"]["featured_profile"] == 0){
               setIsChecked(false);
            }

            

           

        }else{

            console.log(userId+"_____"+communityId);

          MemberDetailsListOWnAdmin({
            "userId":userId,
            "communityId":communityId,
            "original": "en",
            "Id":userId,
            "myuserId":userId,
            "translate": ["en"]
          });


        
        }





      },[communityId , list_member_details_own_admin]);


      useEffect(()=>{

        if(verify_user_profile_list.affectedRows >= 1 || insert_featured_profile_list.affectedRows >= 1 || delete_featured_profile.affectedRows >= 1 && (load == true)){

             alert("Done Successfully");
        }

      },[verify_user_profile_list , insert_featured_profile_list , delete_featured_profile]);





   const  handleclick = (index) => {

        const searchParams = new URLSearchParams(location.search);
        const userId = searchParams.get('userId');
        const communityId = searchParams.get('communityId');
        const username = searchParams.get('username');
        const profile_id = searchParams.get('profile_id');



     if(index == 0 ){

      navigate(`/basic_details_admin?userId=${userId}&communityId=${communityId}&username=${username}&profile_id=${profile_id}`);

     }else if(index == 1){
      navigate(`/contact_details_admin?userId=${userId}&communityId=${communityId}&username=${username}&profile_id=${profile_id}`);
     }else if(index == 2){

      navigate(`/lifestyle_details_admin?userId=${userId}&communityId=${communityId}&username=${username}&profile_id=${profile_id}`);

     }else if(index == 3){
      navigate(`/educational_details_admin?userId=${userId}&communityId=${communityId}&username=${username}&profile_id=${profile_id}`);

     }else if(index == 4){
      navigate(`/occupational_details_admin?userId=${userId}&communityId=${communityId}&username=${username}&profile_id=${profile_id}`);
     }else if(index == 5){
      navigate(`/horoscope_admin?userId=${userId}&communityId=${communityId}&username=${username}&profile_id=${profile_id}`);
     }else if(index == 6){
      navigate(`/family_admin?userId=${userId}&communityId=${communityId}&username=${username}&profile_id=${profile_id}`);
     }else if(index == 7){
      navigate(`/partner_preference_admin?userId=${userId}&communityId=${communityId}&username=${username}&profile_id=${profile_id}`);
     }else if(index == 8){
      navigate(`/basic_details_admin?userId=${userId}&communityId=${communityId}&username=${username}&profile_id=${profile_id}`);
     }else if(index == 9){
      navigate(`/docs_admin?userId=${userId}&communityId=${communityId}&username=${username}&profile_id=${profile_id}`);
     }
 
  
   }


   const [isChecked, setIsChecked] = useState(false);

   // Function to handle checkbox change
   const handleCheckboxChange = (event) => {
     setIsChecked(event.target.checked);
   };


   const saveTopData = () =>{

    const searchParams = new URLSearchParams(location.search);
        const userId = searchParams.get('userId');
        const communityId = searchParams.get('communityId');

    if(isChecked == true){

    insert_featured_profile({
      "userId":userId,
      "communityId":communityId 
   });

    }else if(isChecked == false){

      delete_featured_profile({
        "userId":userId,
        "communityId":communityId 
     });

    }

    let isverify = "0";
      
    if(selectedOption3 == "accept"){
       isverify = "1";
    }else if(selectedOption3 == "reject"){
       isverify = "2";
    }

    verify_user_profile({
      "isverify":isverify,
      "userId":userId,
      "communityId":communityId 
    });


   }

  

    return (<>
        
      {  load == false ?  <RotatingHeart/> : 
    <Grid  container xs={12} spacing={0} style={{ position:"absolute" , top:"100px" ,   justifyContent: "space-evenly" , marginLeft:"2%"  }}>
<Grid item xs={12}><Typography  variant="h6" align="center" width="400px" style={{padding:"5px" , borderRadius:"10px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb" , marginLeft:"30px" }}>{"User Details of " +new URLSearchParams(location.search).get('username')}</Typography></Grid>

<div className="profile-container">
  
  
  <div className="profile"><input type="checkbox" id="featured" name="featured"  checked={isChecked} 
        onChange={handleCheckboxChange} /><label htmlFor="featured">Mark this Profile as Featured Profile</label><div style={{position:"absolute" , right:"200px"}}>
<label style={{fontWeight:"bold"}}>Verify Status of this profile as Verified or Rejected : </label>
            <label>
                <input
                    type="radio"
                    name="acceptReject3"
                    value="accept"
                    checked={selectedOption3 === "accept"}
                    onChange={handleOptionChange3}
                />
                Accept
            </label>
            <label>
                <input
                    type="radio"
                    name="acceptReject3"
                    value="reject"
                    checked={selectedOption3 === "reject"}
                    onChange={handleOptionChange3}
                />
                Reject
            </label>


            <button className="save-button" onClick={saveTopData}>Save</button>

        </div>

        
        


</div>



</div>


    <Grid item md={6} lg={4} xl={4} style={{justifyContent: "space-evenly"}}>
    <Box
      sx={{
        borderRadius: '8px',
        margin: '16px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >

    <SectionBox
        title={"Basic Details"}
        details={
            list_member_details_own_admin.length > 0 && JSON.stringify(list_member_details_own_admin[0][0]) != "{}" ? (
            <>
             <div style={{padding:"10px"}}>
      
      <span className="key1">{t('name_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[0][0]["0"]["fullname"]}</span><br />
              <span className="key1">{t('age_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[0][0]["0"]["age"]}</span><br />
              <span className="key1">{t('marital_status_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[0][0]["0"]["marital_status"]}</span><br />
              <span className="key1">{t('created_by_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[0][0]["0"]["created_by"]}</span><br />
              <span className="key1">{t('caste_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[0][0]["0"]["caste"]}</span><br />
              <span className="key1">{t('subcaste_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[0][0]["0"]["subcaste"]}</span><br />
              <span className="key1">{t('language_known_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[0][0]["0"]["language_known"]}</span><br />
              <span className="key1">{t('mother_tongue_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[0][0]["0"]["mother_tongue"]}</span><br />

      </div>
              
            </>
          ) : (
            ''
          )
        }
        icon={<IconButton onClick={()=>handleclick(0)} style={{padding:"0px" , color:"white"}}><EditIcon/></IconButton>} 
      />

</Box>
</Grid>

<Grid item md={6} lg={4} xl={4}>
    <Box
      sx={{
        borderRadius: '8px',
        margin: '16px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >

<SectionBox
        title={"Contact Details"}
        details={
          list_member_details_own_admin.length > 0 && JSON.stringify(list_member_details_own_admin[1][0]) != "{}" ? (
            <>
             <div style={{padding:"10px"}}>
        <span className="key1">{t('mobile') + ":"}</span> <span className="value1">{list_member_details_own_admin[1][0]["0"]["mobile_number"]}</span><br />
        <span className="key1">{t('contact_whatsapp_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[1][0]["0"]["whatsapp_number"]}</span><br />
        <span className="key1">{t('emailid') + ":"}</span> <span className="value1">{list_member_details_own_admin[1][0]["0"]["emailid"]}</span><br />
        <span className="key1">{t('alternate_mobile_l') + ":"}</span> <span className="value1"> {list_member_details_own_admin[1][0]["0"]["alternate_mobile"]}</span><br />
        <span className="key1">{t('alternate_email_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[1][0]["0"]["alternate_email"]}</span><br />
        <span className="key1">{t('perm_country_label') + ":"}</span> <span className="value1">{list_member_details_own_admin[1][0]["0"]["perm_country"]}</span><br />
        <span className="key1">{t('perm_state_label') + ":"}</span> <span className="value1">{list_member_details_own_admin[1][0]["0"]["perm_state"]}</span><br />
        <span className="key1">{t('perm_city_label') + ":"}</span> <span className="value1">{list_member_details_own_admin[1][0]["0"]["perm_city"]}</span><br />
        <span className="key1">{t('perm_address') + ":"}</span> <span className="value1">{list_member_details_own_admin[1][0]["0"]["permanent_adddress"]}</span><br />
        <span className="key1">{t('work_country_label') + ":"}</span> <span className="value1">{list_member_details_own_admin[1][0]["0"]["work_country"]}</span><br />
        <span className="key1">{t('work_state_label') + ":"}</span> <span className="value1">{list_member_details_own_admin[1][0]["0"]["work_state"]}</span><br />
        <span className="key1">{t('work_city_label') + ":"}</span> <span className="value1">{list_member_details_own_admin[1][0]["0"]["work_city"]}</span><br />
        <span className="key1">{t('working_address') + ":"}</span> <span className="value1">{list_member_details_own_admin[1][0]["0"]["working_address"]}</span><br />
        <span className="key1">{t('contact_time_l_only') + ":"}</span> <span className="value1">{list_member_details_own_admin[1][0]["0"]["contact_time"]}</span><br />

      </div>  

              
            </>
          ) : (
            ''
          )
        }
        icon={<IconButton onClick={()=>handleclick(1)} style={{padding:"0px" , color:"white"}}><EditIcon/></IconButton>}
      />

</Box>
</Grid>



<Grid item md={6} lg={4} xl={4}>
    <Box
      sx={{
        borderRadius: '8px',
        margin: '16px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >

<SectionBox
        title={"LifeStyle Details"}
        details={
          list_member_details_own_admin.length > 0 && JSON.stringify(list_member_details_own_admin[2][0]) != "{}" ? (
            <>
             <div style={{padding:"10px"}}>
      
      <span className="key1">{t('weight_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[2][0]["0"]["weight"]}</span><br />
              <span className="key1">{t('height_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[2][0]["0"]["height"]}</span><br />
              <span className="key1">{t('skintone_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[2][0]["0"]["skintone"]}</span><br />
              <span className="key1">{t('blood_group_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[2][0]["0"]["blood_group"]}</span><br />
              <span className="key1">{t('fitness_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[2][0]["0"]["fitness"]}</span><br />
              <span className="key1">{t('bodytype_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[2][0]["0"]["body_type"]}</span><br />
              <div>{list_member_details_own_admin[2][0]["0"]["is_handicap"] == "1" ? <span className="value1">{list_member_details_own_admin[2][0]["0"]["handicap_detail"]}</span> : <></>}</div>

              <span className="key1">{t('body_type_p_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[2][0]["0"]["body_type"]}</span><br />
              <span className="key1">{t('diet_type_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[2][0]["0"]["diet_type"]}</span><br/>
              <span className="key1">{t('drink_type_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[2][0]["0"]["drink_type"]}</span><br />
              <span className="key1">{t('smoke_type_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[2][0]["0"]["smoke_type"]}</span>
            

      </div> 

              
            </>
          ) : (
            ''
          )
        }
        icon={<IconButton onClick={()=>handleclick(2)} style={{padding:"0px" , color:"white"}}><EditIcon/></IconButton>}
      />
      </Box>
      </Grid>


      <Grid item  md={6} lg={4} xl={4}>
    <Box
      sx={{
        borderRadius: '8px',
        margin: '16px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >

<SectionBox
        title={"Educational Details"}
        details={
          list_member_details_own_admin.length > 0 && JSON.stringify(list_member_details_own_admin[3][0]) != "{}" ? (
            <>
             
      <div style={{padding:"10px"}}>
      
      <div>{list_member_details_own_admin[3][0]["0"]["is_from_admin_service"] == "1" && JSON.stringify(list_member_details_own_admin[3][0]) != "{}" ? <span className="value1">{"The Person is from Administrative Service His position is " + list_member_details_own_admin[3][0]["0"]["admin_position_name"]}</span> : <></>}</div>
              <div>{list_member_details_own_admin[3][0]["0"]["is_from_iit_iim_nit"] == "1" ? <span className="value1">{"The Person is from " + list_member_details_own_admin[3][0]["0"]["institute_name"]}</span> : <></>}</div>
              <span className="key1">{t('education_p_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[3][0]["0"]["education"]}</span><br />
              <span className="key1">{t('education_detail_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[3][0]["0"]["education_detail"]}</span><br />
      </div> 

              
            </>
          ) : (
            ''
          )
        }
        icon={<IconButton onClick={()=>handleclick(3)} style={{padding:"0px" , color:"white"}}><EditIcon/></IconButton>}
      />
      </Box>
      </Grid>   


      <Grid item  md={6} lg={4} xl={4}>
    <Box
      sx={{
        borderRadius: '8px',
        margin: '16px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >

<SectionBox
        title={"Occupational Details"}
        details={
          list_member_details_own_admin.length > 0 && JSON.stringify(list_member_details_own_admin[4][0]) != "{}" ? (
            <>
             
      
             <div style={{padding:"10px"}}>
       <span className="key1">{t('occupation_list_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[4][0]["0"]["occupation"]}</span><br />
       <span className="key1">{t('occupation_details_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[4][0]["0"]["occupation_detail"]}</span><br />
       <span className="key1">{t('annual_income_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[4][0]["0"]["annual_income"]}</span><br />
       <span className="key1">{t('employment_type_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[4][0]["0"]["employment_type"]}</span><br />
       <span className="key1">{t('office_address_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[4][0]["0"]["office_address"]}</span><br />
    
      </div>  

              
            </>
          ) : (
            ''
          )
        }
        icon={<IconButton onClick={()=>handleclick(4)} style={{padding:"0px" , color:"white"}}><EditIcon/></IconButton>}
      />

</Box></Grid>

   
<Grid item   md={6} lg={4} xl={4}>
    <Box
      sx={{
        borderRadius: '8px',
        margin: '16px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
       
      }}
    >

<SectionBox
        title={"Horoscope Details"}
        details={
          list_member_details_own_admin.length > 0 && JSON.stringify(list_member_details_own_admin[6][0]) != "{}" ? (
            <>
             
      
             
      <div style={{padding:"10px"}}>
      
      <span className="key1">{t('birth_date_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[6][0]["0"]["birth_date"]}</span><br />
                    <span className="key1">{t('birth_time_label') + ":"}</span> <span className="value1">{list_member_details_own_admin[6][0]["0"]["birth_time"]}</span><br />
                    <span className="key1">{t('birth_place_label') + ":"}</span> <span className="value1">{list_member_details_own_admin[6][0]["0"]["birth_place"]}</span><br />
                    <span className="key1">{t('mangalik_or_not') + ":"}</span> <span className="value1">{list_member_details_own_admin[6][0]["0"]["is_mangalik"] == "1" ? "This Person is Mangalik" : "This Person is not Mangalik"}</span><br />
                    <span className="key1">{t('believe_horoscope') + ":"}</span> <span className="value1">{list_member_details_own_admin[6][0]["0"]["believe_horoscope"] == "1" ? "Believes in horoscope" : "Does not believe in horoscope"}</span><br />
                    <span className="key1">{t('gotra_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[6][0]["0"]["gotra"]}</span><br />
                    <span className="key1">{t('rashi_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[6][0]["0"]["astro_rashi"]}</span><br />
                    <span className="key1">{t('birth_star_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[6][0]["0"]["birth_star"]}</span><br />
                    <span className="key1">{t('birth_location_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[6][0]["0"]["birth_location"]}</span><br />

      </div>  

              
            </>
          ) : (
            ''
          )
        }
        icon={<IconButton onClick={()=>handleclick(5)} style={{padding:"0px" , color:"white"}}><EditIcon/></IconButton>}
      />

</Box>
</Grid>


<Grid item  md={6} lg={4} xl={4}>
    <Box
      sx={{
        borderRadius: '8px',
        margin: '16px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
       
      }}
    >


<SectionBox
        title={"Family Details"}
        details={
          list_member_details_own_admin.length > 0 && JSON.stringify(list_member_details_own_admin[5][0]) != "{}" ? (
            <>
             
      
             
             <div style={{padding:"10px"}}>
      
      <span className="key1">{t('family_status_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[5][0]["0"]["family_status"]}</span><br />
              <span className="key1">{t('family_type_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[5][0]["0"]["family_type"]}</span><br/>
                <span className="key1">{t('family_value_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[5][0]["0"]["family_value"]}</span><br />
              <span className="key1">{t('no_brother_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[5][0]["0"]["no_brother"]}</span>  <span className="key1">{t('no_sisters_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[5][0]["0"]["no_sister"]}</span><br />
              <span className="key1">{t('no_brothers_married_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[5][0]["0"]["married_brother"]}</span><br/>
                <span className="key1">{t('no_sisters_married_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[5][0]["0"]["married_sister"]}</span><br />
              <span className="key1">{t('father_name_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[5][0]["0"]["father_name"]}</span><br />
              <span className="key1">{t('mother_name_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[5][0]["0"]["mother_name"]}</span><br />
              <span className="key1">{t('father_occupation_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[5][0]["0"]["father_occupation"]}</span><br />
              <span className="key1">{t('mother_occupation_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[5][0]["0"]["mother_occupation"]}</span><br />
              <span className="key1">{t('house_owned_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[5][0]["0"]["house_owned"]}</span><br />
              <span className="key1">{t('house_type_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[5][0]["0"]["house_type"]}</span><br />
              <span className="key1">{t('parent_stay_option_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[5][0]["0"]["parents_stay_options"]}</span><br />
              <span className="key1">{t('details_family_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[5][0]["0"]["detail_family"]}</span>

      </div>  

              
            </>
          ) : (
            ''
          )
        }
        icon={<IconButton onClick={()=>handleclick(6)} style={{padding:"0px" , color:"white"}}><EditIcon/></IconButton>}
      />

</Box>
</Grid>




<Grid item  md={6} lg={4} xl={4}>
    <Box
      sx={{
        borderRadius: '8px',
        margin: '16px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >


<SectionBox
        title={"Partner Preference Details"}
        details={
          list_member_details_own_admin.length > 0 && JSON.stringify(list_member_details_own_admin[8][0]) != "{}" ? (
            <>
             
      
             
             <div style={{padding:"10px"}}>
      
      <span className="key1">{t('age_match_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[8][0]["0"]["age_range"]}</span><br />
              <span className="key1">{t('height_match_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[8][0]["0"]["height_range"]}</span><br/>
                <span className="key1">{t('marital_status_match_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[8][0]["0"]["marital_status"]}</span><br />
              <span className="key1">{t('caste_match_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[8][0]["0"]["caste"]}</span>  <span className="key1">{t('no_sisters_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[5][0]["0"]["no_sister"]}</span><br />
              <span className="key1">{t('subcaste_match_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[8][0]["0"]["subcaste"]}</span><br/>
                <span className="key1">{t('state_match_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[8][0]["0"]["state"]}</span><br />
              <span className="key1">{t('city_match_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[8][0]["0"]["city"]}</span><br />
              <span className="key1">{t('education_match_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[8][0]["0"]["education"]}</span><br />
              <span className="key1">{t('occupation_match_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[8][0]["0"]["occupation"]}</span><br />
              <span className="key1">{t('familyvalue_match_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[8][0]["0"]["family_value"]}</span><br />
              <span className="key1">{t('diettype_match_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[8][0]["0"]["diet_type"]}</span><br />
              <span className="key1">{t('bodytype_match_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[8][0]["0"]["body_type"]}</span><br />
              <span className="key1">{t('drinktype_match_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[8][0]["0"]["drink_type"]}</span><br />
              <span className="key1">{t('smoketype_match_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[8][0]["0"]["smoke_type"]}</span>
              <span className="key1">{t('annual_income_match_l') + ":"}</span> <span className="value1">{list_member_details_own_admin[8][0]["0"]["annual_income"]}</span>
      </div>  

              
            </>
          ) : (
            ''
          )
        }
        icon={<IconButton onClick={()=>handleclick(7)} style={{padding:"0px" , color:"white"}}><EditIcon/></IconButton>}
      />

</Box>
</Grid>







<Grid item   md={6} lg={4} xl={4}>
    <Box
      sx={{
        borderRadius: '8px',
        margin: '16px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >


<SectionBox
        title={t('verification_details')}
        details={
          list_member_details_own_admin.length > 0 && JSON.stringify(list_member_details_own_admin[9][0]) != "{}" ? (
            <>
             
      
             
             <div style={{padding:"10px"}}>
      
             <div>
                                <span className="key1">{t('verified_user') + ":"}</span> <span className="value1">{list_member_details_own_admin[9][0]["0"]["user_verify"] == "0" ? "Verification Process Going on" : list_member_details_own_admin[9][0]["0"]["user_verify"] == "1" ? "Your Id Proof Verified" : "Your Id Is not Verified"}</span><br />
                                <span className="key1">{t('verified_mobile') + ":"}</span> <span className="value1">{list_member_details_own_admin[9][0]["0"]["mobile_verify"] == "0" ? "Verification Process Going on" : list_member_details_own_admin[9][0]["0"]["mobile_verify"] == "1" ? "Mobile number Is Verified" : "Mobile Number Is not Verified"}</span><br />
                                <span className="key1">{t('verified_email') + ":"}</span> <span className="value1">{list_member_details_own_admin[9][0]["0"]["email_verify"] == "0" ? "Verification Process Going on" : list_member_details_own_admin[9][0]["0"]["email_verify"] == "1" ? "Email Id Is Verified" : "Email Id Is not Verified"}</span><br />
                                <span className="key1">{t('verified_id_proof') + ":"}</span> <span className="value1">{list_member_details_own_admin[9][0]["0"]["is_id_verify"] == "0" ? "Verification Process Going on" : list_member_details_own_admin[9][0]["0"]["is_id_verify"] == "1" ? "Identity Is Verified" : "Identity Is not Verified"}</span><br />
                                <span className="key1">{t('verified_edu_proof') + ":"}</span> <span className="value1">{list_member_details_own_admin[9][0]["0"]["is_education_verify"] == "0" ? "Verification Process Going on" : list_member_details_own_admin[9][0]["0"]["is_education_verify"] == "1" ? "Education Proof Is Verified" : "Education Proof Is not Verified"}</span><br />
                                <span className="key1">{t('verified_salary_proof') + ":"}</span> <span className="value1">{list_member_details_own_admin[9][0]["0"]["is_income_verify"] == "0" ? "Verification Process Going on" : list_member_details_own_admin[9][0]["0"]["is_income_verify"] == "1" ? "Salary Proof Is Verified" : "Salary Proof Is not Verified"}</span><br />
                               
                            </div></div>  

              
            </>
          ) : (
            ''
          )
        }
        icon={<EditIcon />} 
      />

</Box>
</Grid>



<Grid item md={6} lg={4} xl={4}>
    <Box
      sx={{
        borderRadius: '8px',
        margin: '16px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >


<SectionBox
        title={t('click_profile_photos')}
        icon={<IconButton onClick={()=>handleclick(8)} style={{padding:"0px" , color:"white"}}><EditIcon/></IconButton>} 
      />

</Box>
</Grid>


<Grid item  md={6} lg={4} xl={4}>
    <Box
      sx={{
        borderRadius: '8px',
        margin: '16px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >

<SectionBox
        title={t('click_proofs_viewes')}
        height="100"
        icon={<IconButton onClick={()=>handleclick(9)} style={{padding:"0px" , color:"white"}}><EditIcon/></IconButton>}  
      />

</Box>
</Grid>


    </Grid>

}

    </>);




}

export default UserDetailsById;