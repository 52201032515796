import { useState } from "react";
import { useBlogsContextAdmin } from "../../contexts_admin/blogs_context";
import { ADMIN_COMMUNITY_ID, COMMUNITY_ID, PROFILE_ID, ROLE } from "../../../common_utils/constants";
import RotatingHeart from "../../../common_utils/loading_animator";
import { useEffect } from "react";
import { Button, CardMedia, Chip, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography, useTheme } from "@mui/material";
import StylishLabel from "../../component/StylishLabel";
import CommunityModal from "../../component/CommunityModal";
import { AboutCommunityProvider } from "../../contexts_admin/aboutcommunity_context";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAboutCommunityContextAdmin } from "../../contexts_admin/aboutcommunity_context";
import StylishLabelOther from "../../component/StylishLabelOther";
import React from "react";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import { useRef } from "react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { width } from "@mui/system";
import RichTextEditor from "../../component/RichTextEditor";



function AddChiefMembersAdmin(){


    const { addChiefMemberDetails , data_add_chiefmembers , updateChiefMemberDetails , data_update_chiefmembers , loading_about_community} = useAboutCommunityContextAdmin();

    const [image, setImage] = useState(null);
    const [files, setFiles] = useState([]);
    const [file, setFile] = useState();
    const [src, setSrc] = useState(null);
    const [crop, setCrop] = useState({ aspect: 4 / 3 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [uploadFiles,  setuploadFiles] = useState("0");
    const cropperRef = useRef(null);

    const [PersonName, setPersonName] = useState("");
    const [Position, setPosition] = useState("");
    const [HtmlContent, setHtmlContent] = useState("");

    const { communityId } = useParams();

    const location = useLocation();


    const downloadFile = async (element ,index) => {

        try {
          const response = await fetch(element);
          const blob = await response.blob();
          const file = new File([blob], element);

        
    
          //setFiles(prevFiles => [...prevFiles, file]);
    
         setFile(file);
          
        } catch (error) {
          console.error('Error fetching or creating file:', error);
        }finally{
    
          alert(files);
           
        }
    
      };

      

    useEffect(()=>{

        const details = location.state?.myObject;

        
        if(details != undefined){

        setPersonName(details.key.name);
        setPosition(details.key.position);
        setImage("https://matriapi.shannishah.com/uploads/matrimonial_photo/Matrimonial_Photo/"+details.key.image);
        setHtmlContent(details.key.description);

        downloadFile("https://matriapi.shannishah.com/uploads/matrimonial_photo/Matrimonial_Photo/"+details.key.image);

        }
   

    },[]);




    const navigate = useNavigate();
    useEffect(()=>{

    

       if(data_add_chiefmembers.affectedRows >= 1 || data_update_chiefmembers.success == "1"){
        
        navigate("/list_chiefmembers");

        window.location.reload();

       }

    } , [data_add_chiefmembers , data_update_chiefmembers]);


    const handlePersonName = (event) => {
        setPersonName(event.target.value);
    };

    const handlePosition = (event) => {
        setPosition(event.target.value);
    };

    const handleContent = (content) => {

        setHtmlContent(content);
    
        console.log(content);
    
       }

    const  addChiefMemberDetailsClick = () => {

      
        if(location.state?.myObject == undefined){

            const formData = new FormData();

            formData.append('sampleFile[]', files[0]);
    
            formData.append("name" , PersonName);
            formData.append("position" , Position);
            formData.append("image" ,  files[0].name);
            formData.append("description" , HtmlContent);
            formData.append("communityId" , communityId);
            formData.append("imagesubpath" , "matrimonial_photo/Matrimonial_Photo/");
    
            

            addChiefMemberDetails(formData);

        }else{

            const formData = new FormData();

            formData.append('sampleFile[]', files[0] == undefined ? file : files[0]);
    
            formData.append("name" , PersonName);
            formData.append("position" , Position);
            formData.append("image" , files[0] == undefined ? location.state?.myObject.key.image  :  files[0].name);
            formData.append("description" , HtmlContent);
            formData.append("communityId" , communityId);
            formData.append("imagesubpath" , "matrimonial_photo/Matrimonial_Photo/");
            formData.append("Id" , location.state?.myObject.key.Id);

            updateChiefMemberDetails(formData);

        }
        


    }
    
    const dropzoneStyle = {
        border: '2px dashed #d9d9d9',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        width: '200px',
        display: 'flex',
        justifyContent: 'center', 
        alignItems : 'center'
 
      };
      
      const fileItemStyle = {
        margin: '10px',
        display: 'inline-block',
      };
      
      const filePreviewStyle = {
        maxWidth: '100%',
        maxHeight: '100%',
      };


    const onDrop = useCallback((acceptedFiles) => {
        // Assuming there is an array of existing files in state called 'files'
        
        setFiles((prevFiles) => [
          ...prevFiles,
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          ),
        ]);

       
    
      }, [setFiles]);
    
    
    
      const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
      });



    return (<div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: "100px", left:"45px" , width: "90%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , backgroundColor:"#F8F8F8"}}>


<Grid container spacing={2}>


        <Grid item xs={12}>

        <div {...getRootProps()}  style={{display:'flex' , justifyContent:"center" , alignContent:"center" , alignItems:"center"}}>
          <input {...getInputProps()} />
          { files.length == 0  ?  <img src={image} width={150} height={170}/>  : <CardMedia component="img" height="220" image = { URL.createObjectURL(files[0])} alt={`Image`}  style={{objectFit:"contain"}} /> }
        </div>

        </Grid>
        
        <Grid item xs={12} style={{marginTop:"20px"}}>
          <TextField
            label="Person Name"
            value={PersonName}
            onChange={handlePersonName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} style={{marginTop:"10px"}}>
          <TextField
            label="Position"
            value={Position}
            onChange={handlePosition}
            fullWidth
          />
        </Grid>

        <Grid item xs={2} style={{marginTop:"0px" ,paddingLeft:"30px"}}><h3>Add Chief Member Details</h3></Grid>
        <Grid item xs={12}>
        <RichTextEditor SentContent={handleContent}  getContent={HtmlContent}/>
        </Grid>
     
        <Grid item xs={12} alignContent={"center"} alignItems={"center"}>
           <Button variant="contained" style={{ backgroundColor: '#d74894', borderRadius: '20px' , marginTop:"15px" , marginBottom:"15px" , fontWeight:"bold" , fontSize:"16px" , justifyContent:"center" , width:"100%"}} onClick={addChiefMemberDetailsClick} >Save Details</Button>
       </Grid>

        
        </Grid>
        
        </div>);




}

export default AddChiefMembersAdmin;