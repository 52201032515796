/*import React from 'react';
import { useState } from 'react';
import { AppBar, Box, Collapse, CssBaseline, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography, useTheme } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';




const Menu = ({ menuItems }) => {


    const drawerWidth = 300;
   const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };




  const [openMenuId, setOpenMenuId] = useState(null);

  const handleClick = (id) => {
    setOpenMenuId(openMenuId === id ? null : id);
  };

  const generateMenuItem = (menuItem) => {
    const isOpen = openMenuId === menuItem.id;

    return (
      <React.Fragment key={menuItem.id}>
        <ListItemButton onClick={() => handleClick(menuItem.id)} 
        selected={1 === menuItem}
        sx={{ color: '#333' , '&.Mui-selected': {
                  backgroundColor: '#333', // Change background color for selected item
                  color: '#fff', // Change text color for selected item
                }, }} >
          <ListItemIcon style={{color: '#ffffff'}}>
            {menuItem.icon}
          </ListItemIcon>
          <ListItemText primary={menuItem.title} sx={{ color: '#fff' }}/>
          {menuItem.subItems && menuItem.subItems.length > 0 && (isOpen ? <ExpandLess style={{color:"#fff"}} /> : <ExpandMore style={{color:"#fff"}} />)}
        </ListItemButton>
        {menuItem.subItems && menuItem.subItems.length > 0 && (
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding  sx={{ color: '#333' }}>
              {menuItem.subItems.map((subItem, index) => (
                <ListItemButton key={index} sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                  <ListItemText primary={subItem} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    );

 }



 const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    
    justifyContent: 'flex-end',
  }));

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
     
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          
      <Drawer
         sx={{
            '& .MuiDrawer-paper': {
              width: 240,
              backgroundColor: '#d3d3d3',
              color: '#333',
            },
          }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>

        <List sx={{ width: '100%', maxWidth: 360, backgroundColor:"#d3d3d3" }} component="nav">
      {menuItems.map(menuItem => generateMenuItem(menuItem))}
    </List>
        
      </Drawer>
    </Box>
  );


  };
  




const CollapsibleMenu = () => {


    const menuItems = [
        { id: 1, title: 'Menu 1', subItems: ['Submenu 1-1', 'Submenu 1-2', 'Submenu 1-3'] , icon:<StarBorder/> },
        { id: 2, title: 'Menu 2' , icon:<StarBorder/>},
        { id: 3, title: 'Menu 3', subItems: ['Submenu 3-1']  , icon:<StarBorder/>}
    ];


    const pinkishTheme = createTheme({
        palette: {
          mode: 'light',
          primary: {
            main: '#e91e63', // Pink color
          },
        },
      });


    return (
        <ThemeProvider theme={pinkishTheme}>
        <Menu menuItems={menuItems} />
      </ThemeProvider>
    );
  };
  
  export default CollapsibleMenu;
*/





// CollapsibleMenu.js

// Assuming you have an SVG file for the dropdown arrow



import React, { useState } from 'react';
import { AppBar, Box, Collapse, CssBaseline, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography, useTheme } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Dots from '@mui/icons-material/RadioButtonChecked';
import Dashboard from '@mui/icons-material/DashboardRounded';
import Members from '@mui/icons-material/SupervisedUserCircle';
import Master from '@mui/icons-material/TableBarOutlined';
import Community from '@mui/icons-material/Group';
import Blog from '@mui/icons-material/Newspaper';
import Event from '@mui/icons-material/Event';
import Files from '@mui/icons-material/FileUpload';
import Payment from '@mui/icons-material/Payment';
import Website from '@mui/icons-material/Web';
import Mobile from '@mui/icons-material/MobileScreenShare';
import Email from '@mui/icons-material/Email';
import User_Roles from '@mui/icons-material/VerifiedUserSharp';
import Analytics from '@mui/icons-material/Analytics';
import SupportTicket from '@mui/icons-material/AirplaneTicket';
import AboutUs from '@mui/icons-material/AbcOutlined';
import Logout from '@mui/icons-material/Logout';
import { ADMIN_IS_LOGIN, ADMIN_NAME, ADMIN_USERID, PERMISSION, ROLE } from '../../common_utils/constants';
import { Paper, Avatar, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const Menu = ({ menuItems }) => {
  const drawerWidth = 300;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [TopLeftPanel, setTopLeftPanel] = React.useState(365);
  const [TopLefdtWidth, setTopLeftWidth] = React.useState("75%");



  const handleDrawerOpen = () => {
    setOpen(true);
    setTopLeftPanel(365);
    setTopLeftWidth("75%");
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setTopLeftPanel(65);
    setTopLeftWidth("95%");
  };


  const AdminStrip = ({ title, dateTime, imageName, role }) => {
  
    return (
      <Paper style={{position:'absolute', top:0 , left:TopLeftPanel , width:TopLefdtWidth , padding:"3px"}}>
        <Grid container style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>

        <Grid Container style={{marginTop:"12px" , marginLeft:"20px"}}>
          <Typography variant="body1" color="textSecondary" style={{fontWeight:"bold" , color:"#e75480" ,fontSize:"18px"}}>{"Community Matrimonial"}</Typography>
          </Grid> 

         
          
          <Grid item style={{ marginTop:"5px" , float:'right' , display:'flex'  , verticalAlign:"center" , paddingRight:"10px" }}>
            
          <Grid item style={{marginTop:"5px" , marginRight:"30px"}}>
          <Typography variant="body1" color="textSecondary" style={{padding:"3px",  fontSize:"15px" ,
  backgroundColor: '#f0f0f0', /* Light grey shade */
  borderRadius: '5px'}}>{dateTime}</Typography>
          </Grid>
    
            
            <Avatar  src={imageName} alt="Image" />

            <Grid item style={{ marginLeft:"5px"}}>
            <Typography>{title}</Typography>
            <Typography style={{marginTop:"-5px"}}>{role}</Typography>
          </Grid>

          </Grid>

          
        </Grid>
        
        
      </Paper>
    );
  };


  const [openMenuId, setOpenMenuId] = useState(null);
  const [SubMenuId, setOpenSubMenuId] = useState(-1);


  const navigate = useNavigate();

  const handleClick = (id) => {
    setOpenMenuId(openMenuId === id ? null : id);
    setOpenSubMenuId(-1);

  
    if(id == 1){
      navigate('/dashboard');
    }else if(id == 6){
       navigate('/list_blogs');
    }else if(id == 7){
      navigate('/events_main');
    }else if(id == 8){
      navigate('/uploadedfiles_main');
    }else if(id == 11){
      navigate('/mobile_theme_manage');
    }else if(id == 12){
      navigate('/email_marketing');
    }else if(id == 13){
      navigate('/list_roles');
    }else if(id == 15){
      navigate('/list_support_ticket');
    }else if(id == 17){
       localStorage.removeItem(ADMIN_USERID);
       localStorage.removeItem(ADMIN_IS_LOGIN);
       window.location.href = '/adminpanel';
    }

  };

  const handleSubItemClick = (id) => {
    setOpenSubMenuId(id);

    if(openMenuId == 2 && id == 0){
      window.location.href ='/free_members';
    }else if(openMenuId == 2 && id == 1){
     window.location.href =  '/premium_members';
    }else if(openMenuId == 2 && id == 2){
      window.location.href = '/deleted_members';
    }else if(openMenuId == 2 && id == 3){
      window.location.href =  '/blocked_members';
    }else if(openMenuId == 2 && id == 5){
      window.location.href = '/unapproved_members';
    }else if(openMenuId == 2 && id == 6){
      navigate('/unapproved_profile_pics');
    }else if(openMenuId == 4 && id == 0){
      navigate('/add_community');
    }else if(openMenuId == 4 && id == 1){
      navigate('/list_community');
    }else if(openMenuId == 5 && id == 0){
      navigate('/religion');
    }else if(openMenuId == 5 && id == 1){
      navigate('/caste');
    }else if(openMenuId == 5 && id == 2){
      navigate('/subcaste');
    }else if(openMenuId == 5 && id == 3){
      navigate('/language');
    }else if(openMenuId == 5 && id == 4){
      navigate('/country');
    }else if(openMenuId == 5 && id == 5){
      navigate('/state');
    }else if(openMenuId == 5 && id == 6){
      navigate('/city');
    }else if(openMenuId == 5 && id == 7){
      navigate('/onbehalf');
    }else if(openMenuId == 5 && id == 8){
      navigate('/fml_status');
    }else if(openMenuId == 5 && id == 9){
      navigate('/fml_value');
    }else if(openMenuId == 5 && id == 10){
      navigate('/diet_type');
    }else if(openMenuId == 9 && id == 0){
      navigate('/list_membership');
    }else if(openMenuId == 9 && id == 1){
      navigate('/list_payments_premium_admin');
    }else if(openMenuId == 10 && id == 0){
      navigate('/web_theme_manage');
    }else if(openMenuId == 16 && id == 0){
      navigate('/list_chiefmembers');
    }else if(openMenuId == 16 && id == 1){
      navigate('/aboutus_adminpanel');
    }else if(openMenuId == 16 && id == 2){
      navigate('/contactus_adminpanel');
    }else if(openMenuId == 16 && id == 3){
      navigate('/list_news');
    }else if(openMenuId == 16 && id == 4){
      navigate('/list_startdate');
    }else if(openMenuId == 16 && id == 5){
      navigate('/list_apk');
    }

    console.log(openMenuId+"______"+id);
  };

  const generateMenuItem = (menuItem) => {
    const isOpen = openMenuId === menuItem.id;

    return (
      <React.Fragment key={menuItem.id}>
        <ListItemButton
          onClick={() => handleClick(menuItem.id)}
          sx={{
            backgroundColor: isOpen ? '#9c27b0' : '#ffffff', // White background
            color: '#333333', // Dark grayish-blue text color
            '&:hover': {
              backgroundColor: '#f3f3f3', // Light grayish-blue background on hover
            },
            '&.Mui-selected': {
              backgroundColor: '#e0e0e0', // Lighter grayish-blue background for selected item
            },
          }}
        >
          <ListItemIcon style={{color: isOpen ? "#ffffff" : "#333"}}>
            {menuItem.icon}
          </ListItemIcon>
          <ListItemText primary={menuItem.title}  style={{color: isOpen ? "#ffffff" : "#333"}}/>
          {menuItem.subItems && menuItem.subItems.length > 0 && (isOpen ? <ExpandLess sx={{ color: isOpen ? "#ffffff" : "#333" }} /> : <ExpandMore sx={{ color: isOpen ? "#ffffff" : "#333" }} />)}
        </ListItemButton>
        {menuItem.subItems && menuItem.subItems.length > 0 && (
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {menuItem.subItems.map((subItem, index) => (
                <ListItemButton key={index} onClick={() => handleSubItemClick(index)} sx={{
                  backgroundColor: index == SubMenuId ? '#9c27b0' : '#ffffff', // White background
                  color: '#333333', // Dark grayish-blue text color
                  '&:hover': {
                    backgroundColor: '#f3f3f3', // Light grayish-blue background on hover
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#e0e0e0', // Lighter grayish-blue background for selected item
                  },
                }}>
                  <ListItemIcon style={{color: index == SubMenuId ? "#ffffff" : "#333"}}>
                    <Dots style={{ width:"15px" , height:"15px"}}/>
                  </ListItemIcon>
                  <ListItemText primary={subItem}  style={{color: index == SubMenuId ? "#ffffff" : "#333"}}/>
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    );
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
  }));

  let role = localStorage.getItem(ROLE);
  let name = localStorage.getItem(ADMIN_NAME);


  const formatDate = (date) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  
    const dayOfWeek = days[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
  
    // Pad minutes with leading zero if less than 10
    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  
    return `${dayOfWeek} ${dayOfMonth} ${month} ${year}, ${hours}:${paddedMinutes}`;
  };

  const currentDate = new Date();

  const coloredIconStyle = {
    filter: 'invert(20%) sepia(98%) saturate(5822%) hue-rotate(308deg) brightness(96%) contrast(90%)',
    width: '70px',
    height:'70px' // Change color to dark pink using CSS filters
  };
  

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
        
        <div style={{padding:"12px"}}>
       <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 2, ...(open && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>
      </div>

      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            backgroundColor: '#fafafa', // Light grayish-blue background for drawer
            color: '#333333', // Dark text color
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>

        <IconButton>
        <img src="/images/community.png" alt="icon" style={coloredIconStyle} />
       </IconButton>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <List sx={{ width: '100%', maxWidth: 360 }}>
          {menuItems.map(menuItem => generateMenuItem(menuItem))}
        </List>
      </Drawer>
        <AdminStrip  dateTime={formatDate(currentDate)}  title={name} imageName={""} role={role}/>
    </Box>
  );
};




const CollapsibleMenu = () => {

  const menuItems = [
    { id: 1, pId:7 , title: 'Dashboard', icon: <Dashboard/> },
    { id: 2, pId:2 , title: 'Members', subItems: ['Free Members', "Premium Members" , "Deleted Mmebers", "Blocked Members" , "Reported Members" , "Unapproved Profiles" , "Unapproved Profile Pics"] , icon: <Members /> },
    { id: 3, pId:12 , title: 'Display User', subItems: ['Smart Search' , 'Matches'], icon: <Members /> },
    { id: 4, pId:-1 ,title: 'Community Data', subItems: ['Add Community' , 'List Community'], icon: <Community /> },
    { id: 5, pId:12 , title: 'Master Pages', subItems: ['Religion' , 'Caste' , 'Subcaste', 'Languages' , "Country" , "State" , "city" , "On Behalf" , "Family Status" , "Family Values" , "Marital Status" , "Diet Type"], icon: <Master /> },
    { id: 6, pId:9 , title: 'Blog', icon: <Blog/> },
    { id: 7, pId:11 , title: 'Event Management', icon: <Event/> },
    { id: 8, pId:1 , title: 'Uploaded Files', icon: <Files/> },
    { id: 9, pId:5 , title: 'Packages & Payments', subItems: ['Package Management ' , 'Premium Members'], icon: <Payment/> },
    { id: 10, pId:1, title: 'Website Management' , subItems: ['Web Management' , 'Notification & Alert'] , icon: <Website/> },
    { id: 11, pId:1, title: 'Mobile Theming', icon: <Mobile/> },
    { id: 12, pId:6 , title: 'Email Marketing', icon: <Email/> },
    { id: 13, pId:8 , title: 'User & Roles', icon: <User_Roles/> },
    { id: 14, pId:4 , title: 'Analytics', icon: <Analytics/> },
    { id: 15, pId:3 ,title: 'Support Ticket', icon: <SupportTicket/> },
    { id: 16, pId:10 , title: 'About Us' , subItems: ['Chief Members' , 'About Us' , 'Contact Us' , 'News' , 'Start Date' , 'Apks'] , icon: <AboutUs/> },
    { id: 17, pId:-1 , title: 'Logout', icon: <Logout/> },
  ];

  let role = localStorage.getItem(ROLE);
  let permission = localStorage.getItem(PERMISSION);
  const permissionsArray = permission.split(',')+",-1";


  let menuItems_filter = [];
  if(role == "sa" || role == "developer"){
    menuItems_filter = menuItems;
  }else{
   menuItems_filter = menuItems.filter(item => permissionsArray.includes(String(item.pId)));
  }

  

  const theme = createTheme({
    typography: {
      fontFamily: 'Arial, sans-serif',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Menu menuItems={menuItems_filter} />
    </ThemeProvider>
  );
};

export default CollapsibleMenu;


