const reducer = (state, action) => {
    switch (action.type) {
      case 'ADD_CHIEFMEMBERS_SUCCESS':
        return {
          ...state,
          data_add_chiefmembers: action.payload,
          loading_about_community: false,
          error_about_community: null,
        };
      case 'ADD_CHIEFMEMBERS_ERROR':
        return {
          ...state,
          data_add_chiefmembers: [],
          loading_about_community: false,
          error_about_community: action.payload,
        };
  
  
        case 'UPDATE_CHIEFMEMBERS_SUCCESS':
        return {
          ...state,
          data_update_chiefmembers: action.payload,
          loading_about_community: false,
          error_about_community: null,
        };
      case 'UPDATE_CHIEFMEMBERS_ERROR':
        return {
          ...state,
          data_update_chiefmembers: [],
          loading_about_community: false,
          error_about_community: action.payload,
        };
  
  
  
      case 'SELECT_CHIEFMEMBERS_SUCCESS':
        return {
          ...state,
          data_select_chiefmembers: action.payload,
          loading_about_community: false,
          error_about_community: null,
        };
      case 'SELECT_CHIEFMEMBERS_ERROR':
        return {
          ...state,
          data_select_chiefmembers: [],
          loading_about_community: false,
          error_about_community: action.payload,
        };
  

        case 'SELECT_ABOUTUS_SUCCESS':
        return {
          ...state,
          data_select_aboutus: action.payload,
          loading_about_community: false,
          error_about_community: null,
        };
      case 'SELECT_ABOUTUS_ERROR':
        return {
          ...state,
          data_select_aboutus: [],
          loading_about_community: false,
          error_about_community: action.payload,
        };
  
       
        case 'INSERT_ABOUTUS_SUCCESS':
          return {
            ...state,
            data_add_aboutus: action.payload,
            loading_about_community: false,
            error_about_community: null,
          };
        case 'INSERT_ABOUTUS_ERROR':
          return {
            ...state,
            data_add_aboutus: [],
            loading_about_community: false,
            error_about_community: action.payload,
          };
  
          

          case 'UPDATE_ABOUTUS_SUCCESS':
            return {
              ...state,
              data_update_aboutus: action.payload,
              loading_about_community: false,
              error_about_community: null,
            };
          case 'UPDATE_ABOUTUS_ERROR':
            return {
              ...state,
              data_update_aboutus: [],
              loading_about_community: false,
              error_about_community: action.payload,
            };
      
  






            case 'SELECT_CONTACTUS_SUCCESS':
        return {
          ...state,
          data_select_contactus: action.payload,
          loading_about_community: false,
          error_about_community: null,
        };
      case 'SELECT_CONTACTUS_ERROR':
        return {
          ...state,
          data_select_contactus: [],
          loading_about_community: false,
          error_about_community: action.payload,
        };
  
       


        case 'INSERT_CONTACTUS_SUCCESS':
          return {
            ...state,
            data_add_contactus: action.payload,
            loading_about_community: false,
            error_about_community: null,
          };
        case 'INSERT_CONTACTUS_ERROR':
          return {
            ...state,
            data_add_contactus: [],
            loading_about_community: false,
            error_about_community: action.payload,
          };
  
          

          case 'UPDATE_CONTACTUS_SUCCESS':
            return {
              ...state,
              data_update_contactus: action.payload,
              loading_about_community: false,
              error_about_community: null,
            };
          case 'UPDATE_CONTACTUS_ERROR':
            return {
              ...state,
              data_update_contactus: [],
              loading_about_community: false,
              error_about_community: action.payload,
            };



      case 'SELECT_NEWS_SUCCESS':
        return {
          ...state,
          data_select_news: action.payload,
          loading_about_community: false,
          error_about_community: null,
        };

      case 'SELECT_NEWS_ERROR':
        return {
          ...state,
          data_select_news: [],
          loading_about_community: false,
          error_about_community: action.payload,
        };


        case 'INSERT_NEWS_SUCCESS':
          return {
            ...state,
            data_add_news: action.payload,
            loading_about_community: false,
            error_about_community: null,
          };
        case 'INSERT_NEWS_ERROR':
          return {
            ...state,
            data_add_news: [],
            loading_about_community: false,
            error_about_community: action.payload,
          };
  
          
          case 'UPDATE_NEWS_SUCCESS':
            return {
              ...state,
              data_update_news: action.payload,
              loading_about_community: false,
              error_about_community: null,
            };
          case 'UPDATE_NEWS_ERROR':
            return {
              ...state,
              data_update_news: [],
              loading_about_community: false,
              error_about_community: action.payload,
            };










            case 'SELECT_APP_BLOCKREASON':
        return {
          ...state,
          data_select_app_blockreason: action.payload,
          loading_about_community: false,
          error_about_community: null,
        };

      case 'SELECT_APP_BLOCKREASON_ERROR':
        return {
          ...state,
          data_select_app_blockreason: [],
          loading_about_community: false,
          error_about_community: action.payload,
        };


        case 'INSERT_APP_BLOCKREASON':
          return {
            ...state,
            data_add_app_blockreason: action.payload,
            loading_about_community: false,
            error_about_community: null,
          };
        case 'INSERT_APP_BLOCKREASON_ERROR':
          return {
            ...state,
            data_add_app_blockreason: [],
            loading_about_community: false,
            error_about_community: action.payload,
          };
  
          
          case 'UPDATE_APP_BLOCKREASON':
            return {
              ...state,
              data_update_app_blockreason: action.payload,
              loading_about_community: false,
              error_about_community: null,
            };
          case 'UPDATE_APP_BLOCKREASON_ERROR':
            return {
              ...state,
              data_update_app_blockreason: [],
              loading_about_community: false,
              error_about_community: action.payload,
            };
  
  

            case 'INSERT_APK_DETAILS':
              return {
                ...state,
                data_add_apk_details: action.payload,
                loading_about_community: false,
                error_about_community: null,
              };
            case 'INSERT_APK_DETAILS_ERROR':
              return {
                ...state,
                data_add_apk_details: [],
                loading_about_community: false,
                error_about_community: action.payload,
              };
  


              case 'SELECT_APK_DETAILS':
              return {
                ...state,
                data_select_apk_details: action.payload,
                loading_about_community: false,
                error_about_community: null,
              };
            case 'SELECT_APK_DETAILS_ERROR':
              return {
                ...state,
                data_select_apk_details: [],
                loading_about_community: false,
                error_about_community: action.payload,
              };

              
      default:
        return state;
    }
  };
  
  export default reducer;