import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Grid, MenuItem ,Pagination , Modal , IconButton} from "@mui/material";
import { ADMIN_COMMUNITY_ID, ROLE } from '../../../common_utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useMasterContextAdmin } from '../../../contexts/master_pageContext';
import { DashboardProvider } from '../../contexts_admin/dashboard_context';
import CommunityModal from '../../component/CommunityModal';
import { useEffect } from 'react';
import StylishLabel from '../../component/StylishLabel';







function Subcaste_page(){

   const {data_insert_subcaste , list_subcaste_admin , data_update_subcaste ,subcaste_fetch , subcaste_insert ,subcaste_update ,religion_fetch ,list_religion_admin , caste_fetch_dropdown , list_castedropdown_admin , delete_master_data} = useMasterContextAdmin();

   const [selectedOption , setSelectedOption] = useState('');
   const [caste_dropdown , setCasteDropdown] = useState('');
   const [caste_dropdown2 , setCasteDropdown2] = useState('');
   const [selectedOption1 , setSelectedOption1] = useState('');
   const [selectedOption2 , setSelectedOption2] = useState('');
 

   
   const [religion, setReligion] = useState('');
   const [subcasteId, sesubCasteId] = useState('');
   const [subcaste, setsubCaste] = useState('');
   const [subcaste2, setsubCaste2] = useState('');
 
   const [open , setopen] = useState(false);
   const [insert , setInsert] = useState(false);
 
   const [openCommunity , setopenCommunity] = useState(false);
   const [communityId, setCommunityId] = useState(1);
   const [currentPage, setCurrentPage] = useState(1);
 
     const handleSubCasteChange = (event) => {
      setsubCaste(event.target.value);
   };
   
 
   const handleCasteChange = (event) => {
      setsubCaste(event.target.value);
   }
 
 
   const handleSubcasteChange2 = (event) => {
     setsubCaste2(event.target.value);
  }
 
   useEffect(()=>{

    let communityId_admin  =  "0";
    if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

      communityId_admin = localStorage.getItem("communityId_admin");
      setCommunityId(communityId_admin);

    }else{
     communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
      setCommunityId(communityId_admin);
    }
 
     religion_fetch();
    // alert(communityId);
 
   },[]);
 
   useEffect(()=>{
     
 
     if((data_insert_subcaste.affectedRows >= 1 &&  insert == true) || (data_update_subcaste.affectedRows >= 1 &&  insert == true) ){
 
 //      alert(selectedOption+"___"+communityId+"____"+currentPage);
 
       subcaste_fetch({
         "casteId": caste_dropdown,
         "communityId": communityId,
         "offset": currentPage == 1 ? 0 : (((currentPage - 2) * 10) + 10)
       });
 
 
 
     }
 
     setInsert(false);
   
   },[data_insert_subcaste , data_update_subcaste]);
 
   const handleOptionChange = (event) => {
 
    alert(communityId);

     setSelectedOption(event.target.value);
     caste_fetch_dropdown({
      "religion_id": event.target.value,
      "communityId": communityId
    });

   }


   const handleCastedropdown = (event) => {

     setCasteDropdown2(event.target.value);

   }

   const handleOptionCastedropdown = (event) => {

    setCasteDropdown(event.target.value);

    let communityId_admin  =  "0";
           if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){
 
             communityId_admin = localStorage.getItem("communityId_admin");
             setCommunityId(communityId_admin);
 
           }else{
            communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
             setCommunityId(communityId_admin);
           }

          
 
     subcaste_fetch({
       "casteId":event.target.value,
       "communityId": communityId_admin,
       "offset":"0"
     });


   }



   const handleDeleteClick = (is_enabled , Id) => {
    delete_master_data({ "type":"subcaste"  , "is_enabled":"1" , "Id":Id});
  
    setTimeout(() => {
  
      subcaste_fetch({
        "casteId": caste_dropdown,
        "communityId": communityId,
        "offset":"0"
      });
    
    }, 500);
  }

 
   const handleOptionChange1 = (event) => {
 
     setSelectedOption1(event.target.value);
 
   }
 
   const handleOptionChange2 = (event) => {
 
     setSelectedOption2(event.target.value);
 
   }
 
   const handleClickCommunity = () => {
     setopenCommunity(true);
   }
 
 
   const handleCloseCommunity = (isopen) => {
     setopenCommunity(isopen);
   }
 
 
 
   const handleClickCommunityLink = (communityId_admin) => {
 
     setCommunityId(communityId_admin);
     localStorage.setItem("communityId_admin" , communityId_admin);
 
   }
 
 
   const handlePageChange = (event, value) => {
   
     setCurrentPage(value);
 
     subcaste_fetch({
       "casteId":caste_dropdown,
       "communityId": communityId,
         "offset": value == 1 ? 0 : (((value - 2) * 10) + 10),
     });
 
  } 
 
    const AddSubCaste = () => {
 
     if(caste_dropdown2 != "" && subcaste != ""){
 
       subcaste_insert({
         "subcaste": subcaste,
         "communityId": communityId,
         "casteId":caste_dropdown2
       });
 
       setInsert(true);
 
     }
   
    }
 
 
 
    const updateCaste = () => {
 
     //alert(subcasteId+"___"+subcaste2);
 
     if(selectedOption != "" && subcaste2 != ""){
 
       subcaste_update({
         "casteId": subcasteId,
         "communityId": communityId,
         "subcaste":subcaste2
       });
 
       setInsert(true);
       setopen(false);
 
     }
   
    }
 
    const handleClose = () => {
     setopen(false);
    };
 
    const handleEditClick = (subcaste , id) => {
   
 
      sesubCasteId(id);
      caste_fetch_dropdown({
        "religion_id": selectedOption,
        "communityId": communityId
      });
     setsubCaste2(subcaste);

    // alert(caste_dropdown);
     setTimeout( function()  {setSelectedOption2(caste_dropdown);  setopen(true);} , 300);
 
    }
 
 
     let role = localStorage.getItem(ROLE);
    

   return (


    <div>{ role == "developer" ? <div><StylishLabel  text={"Select Community"}  clickSelectCommunity={handleClickCommunity} style={{width:"100%"}} /></div> : ""}


        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "150px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , paddingBottom:"20px" , backgroundColor:"#F8F8F8"}}>



        

<Grid container spacing={1}>

<Grid  item xs={3}>
<TextField
      select
      label="Select Religion to List Caste"
      variant="outlined"
      value={selectedOption}
      onChange={handleOptionChange}
      style={{marginTop:"10px" , float:"left" , width:"200px" , marginRight:"20px"}}
      >
      {list_religion_admin.map((option) => (
        <MenuItem key={option.Id} value={option.Id}>
          {option.religion_name}
        </MenuItem>
      ))}
    </TextField>

    </Grid>


    <Grid  item xs={4}>
    <TextField
      select
      label="Select caste to list subcaste"
      variant="outlined"
      value={caste_dropdown}
      onChange={handleOptionCastedropdown}
      style={{marginTop:"10px" , float:"left" , width:"200px" , marginRight:"20px"}}
      >
      {list_castedropdown_admin.length > 0 && list_castedropdown_admin.map((option) => (
        <MenuItem key={option.Id} value={option.Id}>
          {option.caste}
        </MenuItem>
      ))}
    </TextField>

    </Grid>

    <Grid  item xs={6}></Grid>

<Grid  item xs={7}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>SubCaste</TableCell>
                  <TableCell>Caste</TableCell>
                  <TableCell>Update</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list_subcaste_admin .length > 0 && list_subcaste_admin[0].map((religion , index) => (
                  <TableRow key={religion.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
                    <TableCell>{religion.Id}</TableCell>
                    <TableCell>{religion.subcaste}</TableCell>
                    <TableCell>{religion.caste}</TableCell>
                    <TableCell><IconButton onClick={ () => handleEditClick(religion.subcaste , religion.Id)}><EditIcon /></IconButton></TableCell>
                    <TableCell><IconButton onClick={ () => handleDeleteClick("0" , religion.Id)}><DeleteIcon /></IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>



          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
            <Pagination
                count={list_subcaste_admin.length > 0 && Math.ceil(list_subcaste_admin[1][0]["total_row_count"] / 10)}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                size="medium"
            />
        </div>

</Grid>



<Grid  item xs={5}>
<Paper style={{ padding: '20px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
    <Grid container spacing={1}>


    <Grid item xs={12}>

<TextField
  select
  label="Select Caste"
  variant="outlined"
  value={caste_dropdown2}
  onChange={handleCastedropdown}
  fullWidth
>
{list_castedropdown_admin.length > 0 && list_castedropdown_admin.map((option) => (
        <MenuItem key={option.Id} value={option.Id}>
          {option.caste}
        </MenuItem>
      ))}
</TextField>

</Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter Subcaste"
        variant="outlined"
        value={subcaste}
        fullWidth
        onChange={handleSubCasteChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>

    

    <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={AddSubCaste}
        >
        Submit Subcaste
      </Button>

      </Grid>

      </Grid>
    </Paper>

          </Grid>

          </Grid>

        
          {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}



          <Modal open={open} onClose={handleClose}>
      <Grid container spacing={1} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '400px' }}>
      <Grid item xs={12}>

<TextField
  select
  label="Select Caste"
  variant="outlined"
  value={selectedOption2}
  onChange={handleOptionChange2}
  style={{ float:"right" , width:"350px" }}
  >
  {list_castedropdown_admin.map((option) => (
    <MenuItem key={option.Id} value={option.Id}>
      {option.caste}
    </MenuItem>
  ))}
</TextField>


</Grid>

<Grid  item xs={12}>
  <TextField
    label="Enter Subcaste"
    variant="outlined"
    value={subcaste2}
    fullWidth
    onChange={handleSubcasteChange2}
    style={{ marginRight: '10px', flex: 1 }}/>
</Grid>



<Grid item xs={12}>
  <Button
    variant="contained"
    color="primary"
    fullWidth
    onClick={updateCaste}
    >
    Update Caste
  </Button>

  </Grid>
      </Grid>
    </Modal>


        </div>

        </div>
      );




}

export default Subcaste_page;