



import { useState } from "react";
import { useBlogsContextAdmin } from "../../contexts_admin/blogs_context";
import { ADMIN_COMMUNITY_ID, ROLE } from "../../../common_utils/constants";
import RotatingHeart from "../../../common_utils/loading_animator";
import { useEffect } from "react";
import { Chip, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography, useTheme } from "@mui/material";
import StylishLabel from "../../component/StylishLabel";
import CommunityModal from "../../component/CommunityModal";
import { AboutCommunityProvider } from "../../contexts_admin/aboutcommunity_context";
import { useNavigate } from "react-router-dom";
import { useAboutCommunityContextAdmin } from "../../contexts_admin/aboutcommunity_context";
import StylishLabelOther from "../../component/StylishLabelOther";
import React from "react";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";



function AboutChiefMembersAdmin(){

    const { selectChiefMemberDetails , data_select_chiefmembers , loading_about_community} = useAboutCommunityContextAdmin();
    const [load, setLoad] = useState(false);
    const [openCommunity , setopenCommunity] = useState(false);
    const [communityId, setCommunityId] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [Index, setIndex] = useState(-1);
    const [openEvents , setopenEvent] = useState(false);

   

    let role = localStorage.getItem(ROLE);


    const theme = useTheme({
        components: {
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                '& $notchedOutline': {
                  borderColor: 'red', // Change this to your desired outline color
                },
                '&:hover $notchedOutline': {
                  borderColor: '#FFC0CB', // Change this to your desired outline color on hover
                },
              },
            },
          },
        },
      });



    useEffect(()=>{

        if(data_select_chiefmembers.length > 0 || loading_about_community == false){

          setLoad(true);

        }else{


          let communityId_admin  =  "0";
          if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

            communityId_admin = localStorage.getItem("communityId_admin");
            setCommunityId(communityId_admin);

          }else{
           communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
            setCommunityId(communityId_admin);
          }

          selectChiefMemberDetails({
                "communityId":communityId_admin
              });

        }
       
    },[data_select_chiefmembers]);




    const handleClickCommunity = () => {
        setopenCommunity(true);
      }
  
  
      const handleCloseCommunity = (isopen) => {
        setopenCommunity(isopen);
      };
  

  
      const handleClickCommunityLink = (communityId_admin) => {
  
        setCommunityId(communityId_admin);
        localStorage.setItem("communityId_admin" , communityId_admin);
  
        selectChiefMemberDetails({
            "communityId":communityId_admin
          });

      };




    const navigate = useNavigate();
    const addEvents = () => {

       navigate("/add_committee/"+communityId);

    }


    const handleUserdetailsViewClick = (index) => {
      
      setIndex(index);
      hanldeOpenEvent();

    }

      const hanldeOpenEvent = () => {
        setopenEvent(true);
      }
  
  
      const handleCloseEvent = (isopen) => {
        setopenEvent(isopen);
      }



      const handleUpdateEvent = (index) => {

        navigate('/add_committee/'+communityId , {
          // Define your object here
          state: {
            // Define your object here
            myObject: { key: data_select_chiefmembers[index] }
          }
        });


      }


      function getText(html){
        var divContainer= document.createElement("div");
        divContainer.innerHTML = html;
        return divContainer.textContent || divContainer.innerText || "";
    }
   



    return (<>
    

       
       <div  style={{backgroundColor:"#FAFBFD"}}>

        { role == "developer" ? <div><StylishLabelOther  text={"Select Community"}  clickSelectCommunity={handleClickCommunity} style={{width:"140%"}} /></div> : ""}

        <Grid container spacing={2} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "165px" : "100px", left: role == "developer" ? "0px" : "0px" , width: "100%" , paddingRight:"15px"   }}>
        
    


    <Grid key={6} item xs={12} spacing={0} style={{marginBottom:"10px"}}>



<TableContainer component={Paper}>
  <Table>
    <TableHead>
      <Typography  variant="h6" align="left" width="200px" style={{paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb"}}>Committee</Typography>
      <Typography  variant="h6" align="left" width="300px" style={{position:"absolute" , right:"15px" , marginTop:"-32px" ,paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb"}} onClick={addEvents}>Click To Add Committee</Typography>

      <TableRow style={{width:"100%"}}>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Seq.</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Image</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Name</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Position</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Description</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Update ChiefMembers</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {data_select_chiefmembers.length > 0 && data_select_chiefmembers.map((chief_members, index) => (
        <TableRow key={chief_members.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
            <TableCell style={{ color: 'black', fontSize: '15px'  }}>{(index+1)}</TableCell>
            <TableCell><img
        src={"https://matriapi.shannishah.com/uploads/matrimonial_photo/Matrimonial_Photo/"+chief_members.image}
        alt="Description"
        style={{ maxWidth: "35%", height: "auto" }}
      /></TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{chief_members.name}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{chief_members.position}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{getText(chief_members.description)}</TableCell>
    <TableCell><span
      style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }} onClick={() => handleUpdateEvent(index)}>
      {"Update ChiefMembers"}
    </span></TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>







        </Grid>

        </Grid>
    

        {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}
      

    </div>

    
    
    </>);


}

export default AboutChiefMembersAdmin;

