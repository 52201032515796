import React, { createContext, useContext, useReducer } from 'react';
import axios from 'axios';
import reducer from '../../adminpanel/reducer_admin/aboutcommunityReducerAdmin'; // Assuming you have an event reducer
import { BASE_URL } from '../../common_utils/constants';

const aboutcommunityContext = createContext();

const initialState = {
  loading_about_community: true,
  error_about_community: null,
  data_add_chiefmembers: [],
  data_update_chiefmembers: [],
  data_select_chiefmembers: [],
  data_select_aboutus:[],
  data_add_aboutus:[],
  data_update_aboutus: [],
  data_select_contactus:[],
  data_add_contactus:[],
  data_update_contactus: [],

  data_select_news:[],
  data_add_news:[],
  data_update_news: [],

  data_select_app_blockreason:[],
  data_add_app_blockreason:[],
  data_update_app_blockreason: [],

  data_add_apk_details:[],
  data_select_apk_details:[]



};

const API = BASE_URL;

export const AboutCommunityProvider = ({ children }) => {

  const [state, dispatch] = useReducer(reducer , initialState);

  const addChiefMemberDetails   = async (formData) => {
    
    try {
        const response = await axios.post(`${API}`+'adminpanel/role_community/insert_chiefmembers_detail', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        dispatch({ type: 'ADD_CHIEFMEMBERS_SUCCESS' , payload: response.data.data });
        
      } catch (error) {
        dispatch({ type: 'ADD_CHIEFMEMBERS_ERROR' , payload: error });
      }

  };



  const updateChiefMemberDetails   = async (formData) => {
 
    try {
        const response = await axios.post(`${API}`+'adminpanel/role_community/update_chiefmembers_detail', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        dispatch({ type: 'UPDATE_CHIEFMEMBERS_SUCCESS' , payload: response.data });
        
      } catch (error) {
        dispatch({ type: 'UPDATE_CHIEFMEMBERS_ERROR' , payload: error });
      }

  };


  const selectChiefMemberDetails   = async (eventData) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/select_chiefmembers_detail`, eventData);
      dispatch({ type: 'SELECT_CHIEFMEMBERS_SUCCESS', payload: response.data.data, success: response.data.success });
    } catch (error) {
      dispatch({ type: 'SELECT_CHIEFMEMBERS_ERROR', payload: error });
    }
  };


  const selectAboutUsDetails   = async (eventData) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/select_aboutus_detail`, eventData);
      dispatch({ type: 'SELECT_ABOUTUS_SUCCESS', payload: response.data.data, success: response.data.data });
    } catch (error) {
      dispatch({ type: 'SELECT_ABOUTUS_ERROR', payload: error });
    }
  };


  const insertAboutUsDetails   = async (eventData) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/insert_aboutus_detail`, eventData);
      dispatch({ type: 'INSERT_ABOUTUS_SUCCESS', payload: response.data.data, success: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_ABOUTUS_ERROR', payload: error });
    }
  };


  const updateAboutUsDetails   = async (eventData) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/update_aboutus_detail`, eventData);
      dispatch({ type: 'UPDATE_ABOUTUS_SUCCESS', payload: response.data.data, success: response.data.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_ABOUTUS_ERROR', payload: error });
    }
  };




  const selectContactUsDetails   = async (eventData) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/select_contactus_detail`, eventData);
      dispatch({ type: 'SELECT_CONTACTUS_SUCCESS', payload: response.data.data, success: response.data.data });
    } catch (error) {
      dispatch({ type: 'SELECT_CONTACTUS_ERROR', payload: error });
    }
  };


  const insertContactUsDetails   = async (eventData) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/insert_contactus_detail`, eventData);
      dispatch({ type: 'INSERT_CONTACTUS_SUCCESS', payload: response.data.data, success: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_CONTACTUS_ERROR', payload: error });
    }
  };


  const updateContactUsDetails   = async (eventData) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/update_contactus_detail`, eventData);
      dispatch({ type: 'UPDATE_CONTACTUS_SUCCESS', payload: response.data.data, success: response.data.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_CONTACTUS_ERROR', payload: error });
    }
  };



  const selectNewsDetails   = async (eventData) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/select_news_detail`, eventData);
      dispatch({ type: 'SELECT_NEWS_SUCCESS', payload: response.data.data, success: response.data.data });
    } catch (error) {
      dispatch({ type: 'SELECT_NEWS_ERROR', payload: error });
    }
  };


  const insertNewsDetails   = async (eventData) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/insert_news_detail`, eventData);
      dispatch({ type: 'INSERT_NEWS_SUCCESS', payload: response.data.data, success: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_NEWS_ERROR', payload: error });
    }
  };


  const updateNewsDetails   = async (eventData) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/update_news_detail`, eventData);
      dispatch({ type: 'UPDATE_NEWS_SUCCESS', payload: response.data.data, success: response.data.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_NEWS_ERROR', payload: error });
    }
  };






  const select_app_blockreason   = async (eventData) => {
    try {
      const response = await axios.post(`${API}`+`activity/select_app_blockreason`, eventData);
      dispatch({ type: 'SELECT_APP_BLOCKREASON', payload: response.data.data, success: response.data.data });
    } catch (error) {
      dispatch({ type: 'SELECT_APP_BLOCKREASON_ERROR', payload: error });
    }
  };


  const insert_app_blockreason   = async (eventData) => {
    try {
      const response = await axios.post(`${API}`+`activity/insert_app_blockreason`, eventData);
      dispatch({ type: 'INSERT_APP_BLOCKREASON', payload: response.data.data, success: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_APP_BLOCKREASON_ERROR', payload: error });
    }
  };


  const update_app_blockreason   = async (eventData) => {
    try {
      const response = await axios.post(`${API}`+`activity/update_app_blockreason`, eventData);
      dispatch({ type: 'UPDATE_APP_BLOCKREASON', payload: response.data.data, success: response.data.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_APP_BLOCKREASON_ERROR', payload: error });
    }
  };


  const insert_apk_details   = async (eventData) => {
    try {
  
       const response = await axios.post(`${API}`+'adminpanel/role_community/insert_apk_details', eventData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        dispatch({ type: 'INSERT_APK_DETAILS' , payload: response.data.data });
        
    } catch (error) {
      dispatch({ type: 'INSERT_APK_DETAILS_ERROR', payload: error });
    }
  };



  const select_apk_details   = async (eventData) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/select_apk_details` , eventData);
      dispatch({ type: 'SELECT_APK_DETAILS', payload: response.data.data, success: response.data.data });
    } catch (error) {
      dispatch({ type: 'SELECT_APK_DETAILS_ERROR', payload: error });
    }
  };

  
  return (
    <aboutcommunityContext.Provider value={{ ...state, dispatch , addChiefMemberDetails , updateChiefMemberDetails ,selectChiefMemberDetails  , insertAboutUsDetails ,updateAboutUsDetails ,selectAboutUsDetails ,insertContactUsDetails ,updateContactUsDetails ,selectContactUsDetails ,insertNewsDetails ,updateNewsDetails ,selectNewsDetails , select_app_blockreason , insert_app_blockreason , update_app_blockreason ,
      insert_apk_details , select_apk_details
    }}>
      {children}
    </aboutcommunityContext.Provider>
  );
};

export const useAboutCommunityContextAdmin = () => useContext(aboutcommunityContext);