import React from "react";
import { Route, BrowserRouter as Router, Routes, useFetcher , Navigate, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../src/components/Navbar";
import NavbarLogin from "./components/NavbarLogin";
import Login from "../src/pages/login";
import Register from "./pages/register";
import Loading from "./common_utils/loading_animator";
import { MyProvider } from "./contexts/registerContext";
import { ADMIN_IS_LOGIN, COMMUNITY_ID, IS_LOGIN, TRANSLATED_LANG } from "./common_utils/constants";
import HomePage from "./pages/home/homepage";
import { HomeProvider } from "./contexts/homepageContext";
import Strips from "./components/Strips";
import { useState } from "react";
import { translations } from "./data/data";
import { DropdownSelect } from "./app_utils/input_fields";
import { useEffect } from "react";
import "../src/styles/variables.css";

import Navigation123 from "./app_utils/CustomRoute";
import CustomRoute from "./app_utils/CustomRoute";
import Basic_details_input from "./pages/basic_details/basic_detrails_input";
import { BasicProvider } from "./contexts/basicdetailsContext";
import { useTranslation } from 'react-i18next';
import i18n from "../src/translations/en.json";
import { LifestyleProvider } from "./contexts/lifestylephysicalContext";
import LifestyleDetails from "./pages/physical_lifestyles/lifestyle_details_input";
import { EducationProvider } from "./contexts/educationalContext";
import Educational from "./pages/educational_details/educational";
import Occupational_Details from "./pages/occupational_details/occupation_input";
import { OccupationProvider } from "./contexts/occupationdetailsContext";
import { HoroscopeProvider } from "./contexts/horoscopeContext";
import HoroscopeInput from "./pages/horoscope_details/horoscope_input";
import { FamilyProvider } from "./contexts/familyContext";
import FamilyInput from "./pages/family_details/family_input";
import { PartnerPrefsProvider } from "./contexts/partnerPrefsContext";
import PartnerPrefs from "./pages/partner_prefs/partner_prefs_input";
import ContactInput from "./pages/contact_details/contact_input";
import { ContactProvider } from "./contexts/contactdetailsContext";
import { NormalSearchProvider } from "./contexts/normalSearchContext";
import Normal_Search from "./pages/search_type/normal_search";
import { AdvancedSearchProvider } from "./contexts/advancedSearchContext";
import AdvancedSearch from "./pages/search_type/advanced_search";
import IIT_IIM_Search from "./pages/search_type/iit_iim_nit_search";
import NormalSearchResult from "./pages/search_result/normalSearchResult";
import { SearchProvider } from "./contexts/searchContext";
import AdvancedSearchResult from "./pages/search_result/advancedSearchResult";
import IITIIMNITSearchResult from "./pages/search_result/iit_iim_nitSearchResult";
import MatchResult from "./pages/matches/matches";
import MutualMatchResult from "./pages/matches/mutual_matches";
import JustJoinedResult from "./pages/matches/matches_joined";
import ViewProfile from "./pages/activity_details/view_profile";
import { ActivityProvider } from "./contexts/activityContext";
import ShortListActivity from "./pages/activity_details/shortlist_user";
import ContactuserActivity from "./pages/activity_details/contact_user";
import BlockIgnoreActivity from "./pages/activity_details/block_ignore";
import MembershipCard from "./pages/membership/membershipCard";
import MembershipList from "./pages/membership/membershiplist";
import { MembershipProvider } from "./contexts/mmebershipContext";
import Interest_Sent_Recieved from "./pages/activity_details/interest_sent_recieved";
import TimelineData2 from "./pages/profile_detail/profile_detail";
import { MemberDetailsProvider } from "./contexts/memberdetailsContext";
import { NotificationProvider, useNotificationContext } from "./contexts/notificationContext";
import NotificationList from "./pages/notification/notification_list";
import { ProofsProvider } from "./contexts/proofs_context";
import DocumentsUpload from "./pages/document_upload/documentsUpload";
import Settings from "./pages/settings/settings";
import { SettingsProvider } from "./contexts/settingsContext";
import UserOwnDetails from "./pages/user_details/user_details_own";
import { AboutusProvider } from "./contexts/aboutusContext";
import Aboutus from "./pages/about_us/about_us";
import ContactUs from "./pages/about_us/contact_us";
import PrivacyPolicy from "./pages/about_us/privacy_policy";
import ChiefMembersPage from "./pages/about_us/chief_members";
import FaqsPage from "./pages/about_us/faqs";
import NotFoundPage from "./pages/not_found";
import Checkout from "./pages/membership/checkout";
import { I18nextProvider } from 'react-i18next';
import LoginAdmin from "./adminpanel/pages_admin/loginpage";
import { AdminLoginProvider } from "./adminpanel/contexts_admin/login_context";
import CollapsibleMenu from "./adminpanel/component/collapsibleMenu";
import Dashboard from "./adminpanel/pages_admin/dashboard/dashboard";
import { DashboardProvider } from "./adminpanel/contexts_admin/dashboard_context";
import FreeMembers from "./adminpanel/pages_admin/members/free_members";
import { MemberProvider } from "./adminpanel/contexts_admin/member_context";
import UserDetailsById from "./adminpanel/pages_admin/members/UserDetails";
import { BasicProviderAdmin } from "./adminpanel/contexts_admin/members_context/basicdetailsContextAdmin";
import Basic_details_inputAdmin from "./adminpanel/pages_admin/members/basic_details/basic_detrails_inputAdmin";
import { ContactProviderAdmin } from "./adminpanel/contexts_admin/members_context/contactdetailsContextAdmin";
import ContactInputAdmin from "./adminpanel/pages_admin/members/contact_details/contact_inputAdmin";
import { LifestyleProviderAdmin } from "./adminpanel/contexts_admin/members_context/lifestylephysicalContextAdmin";
import LifestyleDetailsAdmin from "./adminpanel/pages_admin/members/physical_lifestyles/lifestyle_details_inputAdmin";
import EducationalAdmin from "./adminpanel/pages_admin/members/educational_details/educationalAdmin";
import { EducationProviderAdmin } from "./adminpanel/contexts_admin/members_context/educationalContextAdmin";
import Occupational_DetailsAdmin from "./adminpanel/pages_admin/members/occupational_details/occupation_inputAdmin";
import { OccupationAdminProvider } from "./adminpanel/contexts_admin/members_context/occupationdetailsContextAdmin";
import { HoroscopAdminProvider } from "./adminpanel/contexts_admin/members_context/horoscopeContextAdmin";
import HoroscopeInputAdmin from "./adminpanel/pages_admin/members/horoscope_details/horoscope_inputAdmin";
import { FamilyAdminProvider } from "./adminpanel/contexts_admin/members_context/familyContextAdmin";
import FamilyInputAdmin from "./adminpanel/pages_admin/members/family_details/family_inputAdmin";
import { PartnerPrefsAdminProvider } from "./adminpanel/contexts_admin/members_context/partnerPrefsContextAdmin";
import PartnerPrefsAdmin from "./adminpanel/pages_admin/members/partner_prefs/partner_prefs_inputAdmin";
import { ProofsAdminProvider } from "./adminpanel/contexts_admin/members_context/proofs_contextAdmin";
import DocumentsUploadAdmin from "./adminpanel/pages_admin/members/document_upload/documentsUploadAdmin";
import PremiumMembers from "./adminpanel/pages_admin/members/premium_members";
import UnapprovedMembers from "./adminpanel/pages_admin/members/unapproved_members";
import DeletedMembers from "./adminpanel/pages_admin/members/deleted_members";
import BlockedMembers from "./adminpanel/pages_admin/members/blocked_member";
import Unapproved_ProfilePics from "./adminpanel/pages_admin/members/unapproved_profilepic";
import AddCommunity from "./adminpanel/pages_admin/community/AddCommunity";
import { CommunityProvider } from "./adminpanel/contexts_admin/community_context";
import ListCommunity from "./adminpanel/pages_admin/community/ListCommunity";
import UpdateCommunity from "./adminpanel/pages_admin/community/UpdateCommunity";
import BlogsList from "./adminpanel/pages_admin/blogs/blogs_list";
import { BlogsProvider } from "./adminpanel/contexts_admin/blogs_context";
import AddBlogs from "./adminpanel/pages_admin/blogs/Add_blogs";
import EventsPage from "./adminpanel/pages_admin/events/EventsMainPage";
import AddSamuhLagan from "./adminpanel/pages_admin/events/AddSamuhLagan";
import { EventProvider } from "./adminpanel/contexts_admin/event_context";
import AddEvent from "./adminpanel/pages_admin/events/AddEvent";
import UpdateEvents from "./adminpanel/pages_admin/events/UpdateEvent";
import UploadedFilesPageMain from "./adminpanel/pages_admin/uploaded_files/UploadedFilesMain";
import { SupportTicketProvider } from "./adminpanel/contexts_admin/supportTicket_context";
import SupportTicketList from "./adminpanel/pages_admin/support_ticket/SupportTicketList";
import AddSupportTikcet from "./adminpanel/pages_admin/support_ticket/AddSupportTicket";
import UpdateSupportTikcet from "./adminpanel/pages_admin/support_ticket/UpdateSupportTikcet";
import Religion from "./adminpanel/pages_admin/master_pages/religion";
import Caste_page from "./adminpanel/pages_admin/master_pages/caste";
import Subcaste_page from "./adminpanel/pages_admin/master_pages/subcaste";
import Language from "./adminpanel/pages_admin/master_pages/languages";
import Country from "./adminpanel/pages_admin/master_pages/country";
import State_page from "./adminpanel/pages_admin/master_pages/state";
import City_page from "./adminpanel/pages_admin/master_pages/city";
import OnBehalf from "./adminpanel/pages_admin/master_pages/on_behalf";
import FamilyStatus from "./adminpanel/pages_admin/master_pages/family_status";
import FamilyValue from "./adminpanel/pages_admin/master_pages/family_value";
import { MasterPageProvider } from "./contexts/master_pageContext";
import { UserRolesProvider } from "./adminpanel/contexts_admin/userRoleContext";
import ListRoles from "./adminpanel/pages_admin/roles_permission/list_roles";
import AddRoles from "./adminpanel/pages_admin/roles_permission/add_roles";
import EmailMarketingPageMain from "./adminpanel/pages_admin/email_marketing/emailmarketingMain";
import { PaymentProvider } from "./adminpanel/contexts_admin/payment_context";
import MembershipListAdmin from "./adminpanel/pages_admin/payment_membership/Membership";
import AddMembership from "./adminpanel/pages_admin/payment_membership/AddMembership";
import UpdateMembership from "./adminpanel/pages_admin/payment_membership/UpdateMembership";
import PaymentListAdmin from "./adminpanel/pages_admin/payment_membership/PaymentList";
import { WebThemeProvider } from "./adminpanel/contexts_admin/web_theme_context";
import WebManagement from "./adminpanel/pages_admin/web_mobile_theme/web_management";
import WebPageMain from "./adminpanel/pages_admin/web_mobile_theme/web_manage_main";
import MobileTheme from "./adminpanel/pages_admin/web_mobile_theme/mobile_theme";
import SelectSavedSearch from "./pages/search_type/select_search";
import Footer from "./components/Footer";
import { Grid } from "@mui/material";
import { useRef } from "react";
import Layout from "./components/Layout";
import "../src/styles/app.css";
import DietType_page from "./adminpanel/pages_admin/master_pages/diet_type";
import { AboutCommunityProvider } from "./adminpanel/contexts_admin/aboutcommunity_context";
import AboutChiefMembersAdmin from "./adminpanel/pages_admin/about_community/about_community_list";
import AddChiefMembersAdmin from "./adminpanel/pages_admin/about_community/add_chief_member";
import AboutUs from "./adminpanel/pages_admin/about_community/aboutus_details";
import ContactUsDetails from "./adminpanel/pages_admin/about_community/contactus_details";
import NewsListAdmin from "./adminpanel/pages_admin/about_community/news_list";
import AddNewsAdmin from "./adminpanel/pages_admin/about_community/add_news";
import StartDate from "./adminpanel/pages_admin/about_community/startdate_list";
import AddStartDate from "./adminpanel/pages_admin/about_community/add_startdate";
import ApkList from "./adminpanel/pages_admin/about_community/apkslist";
import AddApkList from "./adminpanel/pages_admin/about_community/add_apklist";






function App() {

  const {select_web_theme , data_select_webtheme} = useNotificationContext();

  var buttonIsVisible = window.visualViewport.width < window.Width;
  const[path ,setPath] = useState("");
  const[Translations , setTranslations] = useState("Translations");
  const { t , i18n } = useTranslation();
  const contentRef = useRef(null);

  const premiumMembersRef = useRef(null);
  const [premiumMembersHeight, setPremiumMembersHeight] = useState(0);
  


 
  useEffect(()=>{

    setTranslations(localStorage.getItem(TRANSLATED_LANG));
    i18n.changeLanguage(localStorage.getItem(TRANSLATED_LANG));

    select_web_theme({"communityId":localStorage.getItem(COMMUNITY_ID)});


  },[]);

  useEffect(()=>{

    if(data_select_webtheme.length > 0){
      
      //alert(data_select_webtheme[0].theme);
    Object.entries(JSON.parse(data_select_webtheme[0].theme)).forEach(([key, value]) => {
      console.log(key+"---"+value);
      document.documentElement.style.setProperty(key, value);
    });

  }

  },[data_select_webtheme]);

  

  const getPath = (path) =>{
    setPath(path);
  }

  const handleChange = (event) => {
    const value = event.target.value;

    setTranslations(value);
    localStorage.setItem(TRANSLATED_LANG , value);
    i18n.changeLanguage(value);
    window.location.reload();
  }

  
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the scroll position is beyond a certain threshold
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    // Dynamically set the height of the content div
    const updateContentHeight = () => {
      const contentDiv = document.getElementById('content');
      if (contentDiv) {
        contentDiv.style.height = `${document.body.scrollHeight}px`;
      }

      
     
    };

    updateContentHeight();

    // Function to handle resize and update content height
   // const handleResize = () => {
      requestAnimationFrame(updateContentHeight);
   // };

    // Add event listener for resize
    window.addEventListener('resize', updateContentHeight);

    //alert(document.body.scrollHeight);

    return () => {
      window.removeEventListener('resize', updateContentHeight);
    };
  }, []); 
 

    




 const getHeight = (value) => {

      const contentDiv = document.getElementById('content');
      if (contentDiv) {
        contentDiv.style.height = `${value}px`;
      }


     }

  return (

  
    <div>
    
    <div>

    <NotificationProvider>
    <Router>
      {localStorage.getItem(ADMIN_IS_LOGIN) != '1' && window.location.pathname != "/adminpanel" && localStorage.getItem(IS_LOGIN) == '1' ?  <NavbarLogin  passPath={getPath}/> : window.location.pathname != "/adminpanel" && localStorage.getItem(ADMIN_IS_LOGIN) != '1'  ? <Navbar passPath={getPath}/> : "" } 
      {localStorage.getItem(ADMIN_IS_LOGIN) != '1' && window.location.pathname != "/adminpanel" && path != "Home" && path != "/search_result" && window.location.pathname != "/home" && window.location.pathname != "/user_details" && window.location.pathname != "/register" && path != "login" && path != "register" ? <Strips  title={path}/> : ""}
      {localStorage.getItem(ADMIN_IS_LOGIN) == '1' ? <CollapsibleMenu /> : "" }
      
      <Layout>
      <Routes>

   
      {localStorage.getItem(ADMIN_IS_LOGIN) == '1' ?  (<>
      
        <Route path="/dashboard" element={<DashboardProvider><Dashboard /></DashboardProvider>}  />
        <Route path="/free_members" element={<MemberProvider><FreeMembers /></MemberProvider>} />
        <Route path="/premium_members" element={<MemberProvider><PremiumMembers /></MemberProvider>} />
        <Route path="/deleted_members" element={<MemberProvider><DeletedMembers /></MemberProvider>} />
        <Route path="/unapproved_members" element={<MemberProvider><UnapprovedMembers /></MemberProvider>} />
        <Route path="/blocked_members" element={<MemberProvider><BlockedMembers /></MemberProvider>} />
        <Route path="/unapproved_profile_pics" element={<MemberProvider><Unapproved_ProfilePics /></MemberProvider>} />

        <Route path="/user_details_byid"  element={<MemberProvider><UserDetailsById /></MemberProvider>} data={{}} />
        <Route path="/basic_details_admin"  element={<BasicProviderAdmin><Basic_details_inputAdmin/></BasicProviderAdmin>}  />
        <Route path="/contact_details_admin"  element={<ContactProviderAdmin><ContactInputAdmin/></ContactProviderAdmin>}  />
        <Route path="/lifestyle_details_admin"  element={<LifestyleProviderAdmin><LifestyleDetailsAdmin/></LifestyleProviderAdmin>}  />
        <Route path="/educational_details_admin"  element={<EducationProviderAdmin><EducationalAdmin/></EducationProviderAdmin>}  />
        <Route path="/occupational_details_admin"  element={<OccupationAdminProvider><Occupational_DetailsAdmin/></OccupationAdminProvider>}  />
        <Route path="/horoscope_admin"  element={<HoroscopAdminProvider><HoroscopeInputAdmin/></HoroscopAdminProvider>}  />
        <Route path="/family_admin"  element={<FamilyAdminProvider><FamilyInputAdmin/></FamilyAdminProvider>}  />
        <Route path="/partner_preference_admin"  element={<PartnerPrefsAdminProvider><PartnerPrefsAdmin/></PartnerPrefsAdminProvider>}  />
        <Route path="/docs_admin"  element={<ProofsAdminProvider><DocumentsUploadAdmin/></ProofsAdminProvider>}  />
        <Route path="/add_community"  element={<CommunityProvider><AddCommunity/></CommunityProvider>}  />
        <Route path="/update_community" element={<CommunityProvider><UpdateCommunity  /></CommunityProvider>}  />
        <Route path="/list_community"  element={<CommunityProvider><ListCommunity/></CommunityProvider>}  />

        <Route path="/list_chiefmembers"  element={<AboutCommunityProvider><AboutChiefMembersAdmin/></AboutCommunityProvider>}  />
        <Route path="/add_committee/:communityId" element={<AboutCommunityProvider><AddChiefMembersAdmin /></AboutCommunityProvider>} />
        <Route path="/aboutus_adminpanel"  element={<AboutCommunityProvider><AboutUs/></AboutCommunityProvider>}  />
        <Route path="/contactus_adminpanel"  element={<AboutCommunityProvider><ContactUsDetails/></AboutCommunityProvider>}  />
        <Route path="/list_news"  element={<AboutCommunityProvider><NewsListAdmin/></AboutCommunityProvider>}  />
        <Route path="/add_news/:communityId" element={<AboutCommunityProvider><AddNewsAdmin /></AboutCommunityProvider>} />
        <Route path="/add_startdate/:communityId" element={<AboutCommunityProvider><AddStartDate /></AboutCommunityProvider>} />
        <Route path="/list_startdate"  element={<AboutCommunityProvider><StartDate/></AboutCommunityProvider>}  />
        <Route path="/list_apk"  element={<AboutCommunityProvider><ApkList/></AboutCommunityProvider>}  />
        <Route path="/add_apk/:communityId"  element={<AboutCommunityProvider><AddApkList/></AboutCommunityProvider>}  />

        
        <Route path="/list_blogs"  element={<BlogsProvider><BlogsList/></BlogsProvider>}  />
        <Route path="/add_blogs/:communityId"  element={<BlogsProvider><AddBlogs/></BlogsProvider>}  />
        <Route path="/events_main"  element={ <EventsPage/>}  />

        <Route path="/add_samuhlagan/:communityId" element={<EventProvider><AddSamuhLagan /></EventProvider>} />
        <Route path="/add_event/:communityId" element={<EventProvider><AddEvent /></EventProvider>} />
        <Route path="/update_event" element={<EventProvider><UpdateEvents /></EventProvider>} />

        <Route path="/uploadedfiles_main"  element={ <UploadedFilesPageMain />}  />

        <Route path="/list_support_ticket"  element={<SupportTicketProvider><SupportTicketList /></SupportTicketProvider>}  />
        <Route path="/add_support_ticket/:communityId"  element={<SupportTicketProvider><AddSupportTikcet /></SupportTicketProvider>}  />
        <Route path="/update_support_ticket"  element={<SupportTicketProvider><UpdateSupportTikcet /></SupportTicketProvider>}  />

        <Route path="/religion"  element={ <MasterPageProvider><Religion /> </MasterPageProvider>}  />
        <Route path="/caste"  element={ <MasterPageProvider><Caste_page /></MasterPageProvider>}  />
        <Route path="/subcaste"  element={ <MasterPageProvider><Subcaste_page /> </MasterPageProvider>}  />
        <Route path="/language"  element={ <MasterPageProvider><Language /></MasterPageProvider>}  />
        <Route path="/country"  element={ <MasterPageProvider><Country /></MasterPageProvider>}  />
        <Route path="/state"  element={  <MasterPageProvider><State_page /></MasterPageProvider>}  />
        <Route path="/city" element={<MasterPageProvider><City_page /></MasterPageProvider>} />
        <Route path="/onbehalf" element={<MasterPageProvider><OnBehalf /></MasterPageProvider>} />
        <Route path="/fml_status" element={<MasterPageProvider><FamilyStatus /></MasterPageProvider>} />
        <Route path="/fml_value" element={<MasterPageProvider><FamilyValue /></MasterPageProvider>} />
        <Route path="/diet_type"  element={ <MasterPageProvider><DietType_page /> </MasterPageProvider>}  />

        <Route path="/list_roles" element={<UserRolesProvider><ListRoles /></UserRolesProvider>} />
        <Route path="/add_roles/:communityId" element={<UserRolesProvider><AddRoles /></UserRolesProvider>} />

        <Route path="/email_marketing"  element={ <EmailMarketingPageMain />}  />

        <Route path="/list_membership"  element={ <PaymentProvider><MembershipListAdmin /></PaymentProvider>}  />
        <Route path="/add_membership"  element={ <PaymentProvider><AddMembership /></PaymentProvider>}  />
        <Route path="/update_membership"  element={ <PaymentProvider><UpdateMembership /></PaymentProvider>}  />

        <Route path="/list_payments_premium_admin"  element={ <PaymentProvider><PaymentListAdmin /></PaymentProvider>}  />

        <Route path="/web_theme_manage"  element={ <WebPageMain /> }  />
        <Route path="/mobile_theme_manage"  element={ <WebThemeProvider><MobileTheme /></WebThemeProvider> }  />
        

      </>) : localStorage.getItem(IS_LOGIN) == '1' ? (
          <>
      <Route path="/basic" element={<BasicProvider><I18nextProvider i18n={i18n}><Basic_details_input passPath={getPath}  /></I18nextProvider></BasicProvider>} />
      <Route path="/life" element={<LifestyleProvider><I18nextProvider i18n={i18n}><LifestyleDetails passPath={getPath} /></I18nextProvider></LifestyleProvider>} />
      <Route path="/educational" element={<EducationProvider><I18nextProvider i18n={i18n}><Educational  passPath={getPath}/></I18nextProvider></EducationProvider>} />

      <Route path="/occupation" element={<OccupationProvider><I18nextProvider i18n={i18n}><Occupational_Details passPath={getPath} /></I18nextProvider></OccupationProvider>} />
      <Route path="/horoscope" element={<HoroscopeProvider><I18nextProvider i18n={i18n}><HoroscopeInput passPath={getPath} /></I18nextProvider></HoroscopeProvider>} />
      <Route path="/family" element={<FamilyProvider><I18nextProvider i18n={i18n}><FamilyInput passPath={getPath}/></I18nextProvider></FamilyProvider>} />
      <Route path="/partner" element={<PartnerPrefsProvider><I18nextProvider i18n={i18n}><PartnerPrefs passPath={getPath}/></I18nextProvider></PartnerPrefsProvider>} />

      <Route path="/contact" element={<ContactProvider><I18nextProvider i18n={i18n}><ContactInput  passPath={getPath}/></I18nextProvider></ContactProvider>} />

      <Route path="/normal_search"  element={<NormalSearchProvider><I18nextProvider i18n={i18n}><Normal_Search passPath={getPath}/></I18nextProvider></NormalSearchProvider>} />
      <Route path="/advanced_search" element={<AdvancedSearchProvider><I18nextProvider i18n={i18n}><AdvancedSearch passPath={getPath}/></I18nextProvider></AdvancedSearchProvider>} />
      <Route path="/iit_iim_nit_search" element={<NormalSearchProvider><I18nextProvider i18n={i18n}><IIT_IIM_Search passPath={getPath}/></I18nextProvider></NormalSearchProvider>} />
      <Route path="/mangalik_search" element={<NormalSearchProvider><I18nextProvider i18n={i18n}><Normal_Search passPath={getPath}/></I18nextProvider></NormalSearchProvider>} />
      <Route path="/handicap_search" element={<NormalSearchProvider><I18nextProvider i18n={i18n}><Normal_Search passPath={getPath}/></I18nextProvider></NormalSearchProvider>} />
      <Route path="/saved_search" element={<NormalSearchProvider><SelectSavedSearch passPath={getPath}/></NormalSearchProvider>}  />

      <Route path="/normal_search_result" element={<SearchProvider><I18nextProvider i18n={i18n}><NormalSearchResult passPath={getPath}/></I18nextProvider></SearchProvider>} />
      <Route path="/advanced_search_result" element={<SearchProvider><I18nextProvider i18n={i18n}><AdvancedSearchResult passPath={getPath}/></I18nextProvider></SearchProvider>} />
      <Route path="/iit_iim_nit_search_result" element={<SearchProvider><I18nextProvider i18n={i18n}><IITIIMNITSearchResult passPath={getPath}/></I18nextProvider></SearchProvider>} />
   

      <Route path="/matches" element={<SearchProvider><I18nextProvider i18n={i18n}><MatchResult passPath={getPath} /></I18nextProvider></SearchProvider>} />
      <Route path="/mutual_matches" element={<SearchProvider><I18nextProvider i18n={i18n}><MutualMatchResult passPath={getPath}/></I18nextProvider></SearchProvider>} />
      <Route path="/just_joined" element={<SearchProvider><I18nextProvider i18n={i18n}><JustJoinedResult passPath={getPath}/></I18nextProvider></SearchProvider>} />

      <Route path="/viewed_profile/:type" element={<ActivityProvider><I18nextProvider i18n={i18n}><ViewProfile passPath={getPath}/></I18nextProvider></ActivityProvider>} />
      <Route path="/other_view_profile/:type" element={<ActivityProvider><I18nextProvider i18n={i18n}><ViewProfile key="user" passPath={getPath}/></I18nextProvider></ActivityProvider>} />
      <Route path="/shortlisted/:type" element={<ActivityProvider><I18nextProvider i18n={i18n}><ShortListActivity passPath={getPath}/></I18nextProvider></ActivityProvider>} />
      <Route path="/other_shortlists/:type" element={<ActivityProvider><I18nextProvider i18n={i18n}><ShortListActivity key="user2" passPath={getPath}/></I18nextProvider></ActivityProvider>} />

      <Route path="/viewed_contacts/:type" element={<ActivityProvider><I18nextProvider i18n={i18n}><ContactuserActivity passPath={getPath}/></I18nextProvider></ActivityProvider>} />
      <Route path="/other_view_contacts/:type" element={<ActivityProvider><I18nextProvider i18n={i18n}><ContactuserActivity key="user3" passPath={getPath}/></I18nextProvider></ActivityProvider>} />

      <Route path="/ignored_profiles/:type" element={<ActivityProvider><I18nextProvider i18n={i18n}><BlockIgnoreActivity passPath={getPath}/></I18nextProvider></ActivityProvider>} />
      <Route path="/blocked_profiles/:type" element={<ActivityProvider><I18nextProvider i18n={i18n}><BlockIgnoreActivity key="user4" passPath={getPath}/></I18nextProvider></ActivityProvider>} />


       <Route path="/interset_sent/:type" element={<ActivityProvider><I18nextProvider i18n={i18n}><Interest_Sent_Recieved passPath={getPath}/></I18nextProvider></ActivityProvider>} />
      <Route path="/interset_recieved/:type" element={<ActivityProvider><I18nextProvider i18n={i18n}><Interest_Sent_Recieved key="user5" passPath={getPath}/></I18nextProvider></ActivityProvider>} />
      <Route path="/accepted_request/:type" element={<ActivityProvider><I18nextProvider i18n={i18n}><Interest_Sent_Recieved key="user6" passPath={getPath}/></I18nextProvider></ActivityProvider>} />

      
      <Route path="/membership" element={<MembershipProvider><I18nextProvider i18n={i18n}><MembershipList passPath={getPath}/></I18nextProvider></MembershipProvider>} />
      <Route path="/checkout/:parameterValue/:paramvalue2" element={<MembershipProvider><I18nextProvider i18n={i18n}><Checkout passPath={getPath}/></I18nextProvider></MembershipProvider>} />

      <Route path="/user_details" element={<MemberDetailsProvider><I18nextProvider i18n={i18n}><TimelineData2 passPath={getPath}/></I18nextProvider></MemberDetailsProvider>} />
      <Route path="/notification_list" element={<NotificationList/>} />

      <Route path="/docs" element={<ProofsProvider><I18nextProvider i18n={i18n}><DocumentsUpload  passPath={getPath}/></I18nextProvider></ProofsProvider>} />
      <Route path="/settings" element={<SettingsProvider><I18nextProvider i18n={i18n}><Settings passPath={getPath}/></I18nextProvider></SettingsProvider>} />
      <Route path="/all_details" element={<MemberDetailsProvider><I18nextProvider i18n={i18n}><UserOwnDetails passPath={getPath} passHeight={getHeight}/></I18nextProvider></MemberDetailsProvider>} />
      </>
      ):

      (<>
      <Route path="/login" element={<MyProvider><I18nextProvider i18n={i18n}><Login passPath={getPath}/></I18nextProvider></MyProvider>} />
      <Route path="/register" element={<MyProvider><I18nextProvider i18n={i18n}><Register passPath={getPath}/></I18nextProvider></MyProvider>} />
      <Route path="/home"  element={<HomeProvider><I18nextProvider i18n={i18n}><HomePage passPath={getPath}/></I18nextProvider></HomeProvider>}  />
      <Route path="/about" element={<AboutusProvider><I18nextProvider i18n={i18n}><Aboutus passPath={getPath}/></I18nextProvider></AboutusProvider>} />
      <Route path="/contact_us" element={<AboutusProvider><I18nextProvider i18n={i18n}><ContactUs passPath={getPath}/></I18nextProvider></AboutusProvider>} />
      <Route path="/privacy" element={<AboutusProvider><I18nextProvider i18n={i18n}><PrivacyPolicy passPath={getPath}/></I18nextProvider></AboutusProvider>} />
      <Route path="/chief" element={<AboutusProvider><I18nextProvider i18n={i18n}><ChiefMembersPage passPath={getPath}/></I18nextProvider></AboutusProvider>} />
      <Route path="/faqs" element={<AboutusProvider><I18nextProvider i18n={i18n}><FaqsPage passPath={getPath}/></I18nextProvider></AboutusProvider>} />
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route path="/contact" element={<AboutusProvider><I18nextProvider i18n={i18n}><ContactUs passPath={getPath}/></I18nextProvider></AboutusProvider>} />

      <Route path="/search" element={<NormalSearchProvider><I18nextProvider i18n={i18n}><Normal_Search passPath={getPath}/></I18nextProvider></NormalSearchProvider>} />
      <Route path="/normal_search_result" element={<SearchProvider><I18nextProvider i18n={i18n}><NormalSearchResult passPath={getPath}/></I18nextProvider></SearchProvider>} />

      </>
      )}
      
      
      <Route path="/adminpanel" element={<AdminLoginProvider><LoginAdmin /></AdminLoginProvider>} />

      <Route path="*" element={<NotFoundPage/>} />

        
      </Routes>

      </Layout>
    </Router>


    </NotificationProvider>

    { window.location.pathname != "/adminpanel" && localStorage.getItem(ADMIN_IS_LOGIN) != '1'  ? <div style={{ position:"fixed" , bottom:"5px" , right:"5px" ,zIndex:"30" }}>
    <DropdownSelect options={translations} label={"Translate Here"} focusedBorderColor={"#E3256B"}
                            borderColor={"#E3256B"} hoverBorderColor={"#E3256B"} height={50}
                            onChange={handleChange}
                            value={Translations}
                            sx={{ width: "150%", backgroundColor: "#E3256B", color: "white"}} />


    </div> : ""
    }

   
    </div>

    
   <div className={`fixed-bottom ${isVisible ? 'visible' : ''}`}>

    

  </div>
  
  <div>
       
      
      </div>

    

      </div>

   
    
  );

}

export default App;




