import { Button, Grid } from "@mui/material";
import StylishLabelOther from "../../component/StylishLabelOther";
import { ROLE } from "../../../common_utils/constants";
import { useAboutCommunityContextAdmin } from "../../contexts_admin/aboutcommunity_context";
import CommunityModal from "../../component/CommunityModal";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import { useEffect } from "react";
import { useState } from "react";
import RichTextEditor from "../../component/RichTextEditor";



function ContactUsDetails(){


    const { selectContactUsDetails , insertContactUsDetails , updateContactUsDetails ,  data_add_contactus , data_update_contactus , data_select_contactus   , loading_about_community} = useAboutCommunityContextAdmin();
    const [HtmlContent, setHtmlContent] = useState("");
    const [update, setUpdate] = useState(false);
    const [load, setLoad] = useState(false);
    const [openCommunity , setopenCommunity] = useState(false);
    const [communityId, setCommunityId] = useState(1);

    let role = localStorage.getItem(ROLE);

    useEffect(()=>{

        if(data_select_contactus.length != 0){
            setUpdate(true);
            setHtmlContent(data_select_contactus[0].details);
        }else{
            setUpdate(false);
            setHtmlContent("");
        }
        
        
    },[data_select_contactus]);


    useEffect(()=>{

        if(data_add_contactus.affectedRows >= 1 ||  data_update_contactus.affectedRows >= 1){

            alert("Changes Saved Successfully");

        }
           
    
    },[data_add_contactus , data_update_contactus]);


    const addUpdateContactUs = () => {

        if(update == false){
           
            insertContactUsDetails({
                "details":HtmlContent ,
                "communityId":communityId
            });

        }else{

            updateContactUsDetails({
                "details":HtmlContent ,
                "communityId":communityId
            });


        }


    }

    const handleContent = (content) => {

        setHtmlContent(content);
    
    }


    const handleClickCommunity = () => {
        setopenCommunity(true);
      }
  
  
      const handleCloseCommunity = (isopen) => {
        setopenCommunity(isopen);
      };
  

  
      const handleClickCommunityLink = (communityId_admin) => {
  
        setCommunityId(communityId_admin);
        localStorage.setItem("communityId_admin" , communityId_admin);
  

        selectContactUsDetails({
         "communityId":communityId_admin
        });
    

      };




    return (<>
    

       
        <div  style={{backgroundColor:"#FAFBFD"}}>
 
         
 
         <Grid container spacing={2} style={{padding:"50px"}}>

         <Grid item xs={12}>{ role == "developer" ? <div><StylishLabelOther  text={"Select Community"}  clickSelectCommunity={handleClickCommunity} style={{width:"100%"}} /></div> : ""}</Grid>

<Grid item xs={12} style={{marginTop:"50px" , fontSize:"26px" , color:"#000000"}}>Add Contact Us Details</Grid>
<Grid item xs={12}>
<RichTextEditor SentContent={handleContent}  getContent={HtmlContent}/>
</Grid>

<Grid item xs={12} alignContent={"center"} alignItems={"center"}>
   <Button variant="contained" style={{ backgroundColor: '#d74894', borderRadius: '20px' , marginTop:"15px" , marginBottom:"15px" , fontWeight:"bold" , fontSize:"16px" , justifyContent:"center" , width:"100%"}} onClick={addUpdateContactUs} >Save Details</Button>
</Grid>


</Grid>
     
 
         {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}
       
 
     </div>
 
     
     
     </>);
 


}

export default ContactUsDetails;