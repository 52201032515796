import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Grid, MenuItem, Pagination, IconButton, Modal } from "@mui/material";
import { ADMIN_COMMUNITY_ID, ROLE } from '../../../common_utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StylishLabel from '../../component/StylishLabel';
import { useMasterContextAdmin } from '../../../contexts/master_pageContext';
import { DashboardProvider } from '../../contexts_admin/dashboard_context';
import CommunityModal from '../../component/CommunityModal';
import StylishLabelOther from '../../component/StylishLabelOther';
import { useEffect } from 'react';







function DietType_page(){
  
  const { list_diet_fetch , data_insert_diet ,data_update_diet ,data_delete_diet ,diettype_fetch ,diettype_insert ,diettype_update ,diettype_delete} = useMasterContextAdmin();

  const [diet_type, setDietType] = useState('');
  const [diet_type_update, setDietTypeUpdate] = useState('');
  const [diettype_id, setDietTypeId] = useState('');

  const [diet_type2, setDietType2] = useState('');

  const [open , setopen] = useState(false);
  const [insert , setInsert] = useState(false);

  const [openCommunity , setopenCommunity] = useState(false);
  const [communityId, setCommunityId] = useState(1);
  const [Id, setId] = useState('');

  const [isdispaly , setdisplay] = useState(false);


 useEffect(()=>{

  setdisplay(false);
       if(data_delete_diet[0] != undefined){

            diettype_delete({"Id":Id});

          setTimeout(()=>{

            diettype_fetch({
              "communityId": communityId
            });

          },500);


       }
       

    },[data_delete_diet]);

 const handleDeleteClick = (is_enabled , Id) => {
  //delete_master_data({ "type":"caste"  , "is_enabled":"1" , "Id":Id});

  diettype_delete({"Id" :Id});

  setId(Id);

    setTimeout(() => {
      let communityId_admin  =  "0";

      if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){
  
        communityId_admin = localStorage.getItem("communityId_admin");
        setCommunityId(communityId_admin);
  
        
      }else{
       communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
        setCommunityId(communityId_admin);
      }
  

      setdisplay(true);
      diettype_fetch({
        "communityId": communityId
      });
     
      } , 500);

  
  
}

  useEffect(()=>{

   diettype_fetch({
    "communityId": communityId
  });

  },[]);

  useEffect(()=>{
    

    if((data_insert_diet.affectedRows >= 1 &&  insert == true) || (data_update_diet.affectedRows >= 1 &&  insert == true) ){

     // alert(selectedOption+"___"+communityId+"____"+currentPage);

      diettype_fetch({
        "communityId": communityId
      });



    }

    setInsert(false);
  
  },[data_insert_diet , data_update_diet]);
  

 

  const handleClickCommunity = () => {
    setopenCommunity(true);
  }


  const handleCloseCommunity = (isopen) => {
    setopenCommunity(isopen);
  }



  const handleClickCommunityLink = (communityId_admin) => {

    setCommunityId(communityId_admin);
    localStorage.setItem("communityId_admin" , communityId_admin);

    setTimeout( function()  { 

        diettype_fetch({
            "communityId": communityId_admin
          });
    


      } , 300);

  }


  const handleDietChange = (event) => {
    setDietType(event.target.value);
  }


  const handleDietChange2 = (event) => {
    setDietType2(event.target.value);
  }
  

   const AddDietType = () => {

    if(diet_type != ""){

      diettype_insert({
        "label": diet_type,
        "communityId": communityId,
        "value":"0"
      });

      setInsert(true);

    }
  
   }



   const updateDietType = () => {

    //alert(selectedOption+"___"+caste2);

    if(diet_type2 != ""){

      diettype_update({
        "Id": diettype_id,
        "label": diet_type2
      });

      setInsert(true);
      setopen(false);

    }
  
   }

   const handleClose = () => {
    setopen(false);
   };

   const handleEditClick = (label , id) => {
  

    setDietTypeId(id);
    setDietType2(label);
    setTimeout( function()  { setopen(true);} , 300);

   }


    let role = localStorage.getItem(ROLE);
  
   return (

    <div>{ role == "developer" ? <div><StylishLabel  text={"Select Community"}  clickSelectCommunity={handleClickCommunity} style={{width:"100%"}} /></div> : ""}
                            
     

        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "150px" : "150px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , paddingBottom:"20px" , backgroundColor:"#F8F8F8"}}>


<Grid container spacing={1}>

<Grid  item xs={7}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Diet Type</TableCell>
                  <TableCell>Update</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list_diet_fetch.length > 0 && list_diet_fetch.map((diet , index) => (
                  <TableRow key={diet.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
                    <TableCell>{diet.Id}</TableCell>
                    <TableCell>{diet.label}</TableCell>
                    <TableCell><IconButton onClick={ () => handleEditClick(diet.label , diet.Id)}><EditIcon /></IconButton></TableCell>
                    <TableCell><IconButton onClick={ () => handleDeleteClick("0" , diet.Id)}><DeleteIcon /></IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>



</Grid>



<Grid  item xs={5} >
<Paper style={{ padding: '20px', borderRadius: '10px', display: 'flex' , height:"200px" }}>
    <Grid container spacing={1}>

    <Grid  item xs={12}>
      <TextField
        label="Enter Diet Type"
        variant="outlined"
        value={diet_type}
        fullWidth
        onChange={handleDietChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>

    

    <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
         onClick={AddDietType}>
        Add DietType
      </Button>

      </Grid>

      </Grid>
    </Paper>

          </Grid>

          </Grid>

          {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}


          <Modal open={open} onClose={handleClose}>
      <Grid container spacing={1} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '400px' }}>
    

<Grid  item xs={12}>
  <TextField
    label="Enter Diet Type"
    variant="outlined"
    value={diet_type2}
    fullWidth
    onChange={handleDietChange2}
    style={{ marginRight: '10px', flex: 1 }}/>
</Grid>



<Grid item xs={12}>
  <Button
    variant="contained"
    color="primary"
    fullWidth
     onClick={updateDietType}
    >
    Update DietType
  </Button>

  </Grid>
      </Grid>
    </Modal>

    

        </div>

        </div>
      );




}

export default DietType_page;