import { useState } from "react";
import { useBlogsContextAdmin } from "../../contexts_admin/blogs_context";
import { ADMIN_COMMUNITY_ID, COMMUNITY_ID, PROFILE_ID, ROLE } from "../../../common_utils/constants";
import RotatingHeart from "../../../common_utils/loading_animator";
import { useEffect } from "react";
import { Button, CardMedia, Chip, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography, useTheme } from "@mui/material";
import StylishLabel from "../../component/StylishLabel";
import CommunityModal from "../../component/CommunityModal";
import { AboutCommunityProvider } from "../../contexts_admin/aboutcommunity_context";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAboutCommunityContextAdmin } from "../../contexts_admin/aboutcommunity_context";
import StylishLabelOther from "../../component/StylishLabelOther";
import React from "react";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import { useRef } from "react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { width } from "@mui/system";
import RichTextEditor from "../../component/RichTextEditor";



function AddNewsAdmin(){


    const { insertNewsDetails , data_add_news , updateNewsDetails , data_update_news , loading_about_community} = useAboutCommunityContextAdmin();

    const [image, setImage] = useState(null);
    const [files, setFiles] = useState([]);
    const [file, setFile] = useState();
    const [src, setSrc] = useState(null);
    const [crop, setCrop] = useState({ aspect: 4 / 3 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [uploadFiles,  setuploadFiles] = useState("0");
    const cropperRef = useRef(null);

    const [Title , setTitle] = useState("");
    const [ShortDesc , setShortDescription] = useState("");
    const [FullDescription , setFullDescription] = useState("");
    const [HtmlContent, setHtmlContent] = useState("");

    const { communityId } = useParams();

    const location = useLocation();




      

    useEffect(()=>{

        const details = location.state?.myObject;

        

        if(details != undefined){

        setTitle(details.key.title);
        setShortDescription(details.key.short_details);
        setHtmlContent(details.key.full_details);

        }
   

    },[]);




    const navigate = useNavigate();
    useEffect(()=>{

    

       if(data_add_news.affectedRows >= 1 || data_update_news.affectedRows >= 1){
        
        navigate("/list_news");
        window.location.reload();

       }

    } , [data_add_news , data_update_news]);


    const handleTitle = (event) => {
        setTitle(event.target.value);
    };

    const handleShortDEsc = (event) => {
        setShortDescription(event.target.value);
    };

   
    const handleContent = (content) => {

        setHtmlContent(content);
    
        console.log(content);
    
       }

    const  addNewsDetailsClick = () => {

      
        if(location.state?.myObject == undefined){

            insertNewsDetails({
                "title": Title,
                "short_details":ShortDesc,
                "full_details" : HtmlContent,
                "communityId" : communityId
            });

        }else{

            updateNewsDetails({
                "title": Title,
                "short_details":ShortDesc,
                "full_details" : HtmlContent,
                "Id" :  location.state?.myObject.key.Id
            });

        }
        


    }
    
    const dropzoneStyle = {
        border: '2px dashed #d9d9d9',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        width: '200px',
        display: 'flex',
        justifyContent: 'center', 
        alignItems : 'center'
 
      };
      
      const fileItemStyle = {
        margin: '10px',
        display: 'inline-block',
      };
      
      const filePreviewStyle = {
        maxWidth: '100%',
        maxHeight: '100%',
      };


    const onDrop = useCallback((acceptedFiles) => {
        // Assuming there is an array of existing files in state called 'files'
        
        setFiles((prevFiles) => [
          ...prevFiles,
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          ),
        ]);

       
    
      }, [setFiles]);
    
    
    
      const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
      });



    return (<div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: "100px", left:"45px" , width: "90%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , backgroundColor:"#F8F8F8"}}>


<Grid container spacing={2}>


        
        
        <Grid item xs={12} style={{marginTop:"20px"}}>
          <TextField
            label="Enter News Title"
            value={Title}
            onChange={handleTitle}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} style={{marginTop:"10px"}}>
          <TextField
            label="Enter Short Description"
            value={ShortDesc}
            onChange={handleShortDEsc}
            fullWidth
          />
        </Grid>

        <Grid item xs={2} style={{marginTop:"0px" ,paddingLeft:"30px"}}><h3>Add News Details</h3></Grid>
        <Grid item xs={12}>
        <RichTextEditor SentContent={handleContent}  getContent={HtmlContent}/>
        </Grid>
     
        <Grid item xs={12} alignContent={"center"} alignItems={"center"}>
           <Button variant="contained" style={{ backgroundColor: '#d74894', borderRadius: '20px' , marginTop:"15px" , marginBottom:"15px" , fontWeight:"bold" , fontSize:"16px" , justifyContent:"center" , width:"100%"}} onClick={addNewsDetailsClick} >Save Details</Button>
       </Grid>

        
        </Grid>
        
        </div>);




}

export default AddNewsAdmin;