import { FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import RotatingHeart from "../../../common_utils/loading_animator";
import { useState } from "react";
import { DashboardProvider, useAdminDashboardContext } from "../../contexts_admin/dashboard_context";
import { useEffect } from "react";
import { ADMIN_COMMUNITY_ID, ROLE } from "../../../common_utils/constants";
import { BarChart, PieChart, pieArcLabelClasses  } from "@mui/x-charts";
import StylishLabel from "../../component/StylishLabel";
import CommunityModal from "../../component/CommunityModal";
import React from "react";



function Dashboard(){


    const { dashboard ,  data_dashboard_admin , loading_dashboard_admin} = useAdminDashboardContext();
    const [load, setLoad] = useState(false);
    const [counts, setCounts] = useState([]);

    const [years_option, setyear] = useState([]);
    const [month, setMonth] = useState([]);
    const [TotalEarnings, setTotalEarnings] = useState([]);
    const [arcLabel , setgetArcLabel] = useState(""); 
    const [openCommunity , setopenCommunity] = useState(false);

    const [selectedValue, setSelectedValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [communityId, setCommunityId] = useState(1);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);

  

    if(event.target.value != "Select Year of Earnings"){

        const communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);

        
      
        setTimeout(() => {

          const input = {
            "communityId": communityId_admin == "-1" ? communityId : communityId_admin,
            "year":event.target.value,
            "offset":"0"
         };

          dashboard(input);

        }, 200);
        

    }
  
  };

    const init_age_group = [
        { label: 'Age 18-25', value: 0 , color: '#0088FE' },
        { label: 'Age 26-30', value: 0 , color: '#00C49F'},
        { label: 'Age 31-35', value: 0 ,color: '#FFBB28'},
        { label: 'Age 36-40', value: 0 , color: '#FF8042'},
        { label: 'Age 41-45', value: 0 , color: '#333'},
        { label: 'Age 46-50', value: 0 , color: '#d3d3d3'}
      ];
    const [data , setDataagegroup] = useState(init_age_group);


    const init_gender = [
        { label: 'Male', value: 0 , color: '#0088FE' },
        { label: 'Female', value: 0 , color: '#00C49F'},
    ];
    const [data_gender , setInitGender] = useState(init_gender);


    const init_members = [
        { label: 'Total Members', value: 0 , color: '#DAE9F0' },
        { label: 'Premium Members', value: 0 , color: '#E3F498'},
        { label: 'Free Members', value: 0 , color: '#DCD2ED' },
        { label: 'Blocked Members', value: 0 , color: '#FFD3C6'}
    ];
    const [data_members , setMembers] = useState(init_members);


    const items = [
        { title: 'Total Members', count: '0', color: '#DAE9F0' },
        { title: 'Premium Members', count: '0', color: '#E3F498' },
        { title: 'Free Members', count: '0', color: '#DCD2ED' },
        { title: 'Blocked Members', count: '0', color: '#FFD3C6' }
      ];


      const months_label = [
        'Jan', 'Feb', 'Mar', 'April', 'May', 'June',
        'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
      ];


      const sizing = {
        margin: { right: 5 },
        width: 300,
        height: 300,
        legend: { hidden: true },
      };



    useEffect(()=>{

      
        if(data_dashboard_admin.length > 0){


          setLoad(true);
        
           
            setCounts([data_dashboard_admin[0][0]["total_members"] , data_dashboard_admin[1][0]["premium_members"] , data_dashboard_admin[2][0]["free_members"] , data_dashboard_admin[3][0]["blocked_members"] ]);

            let months = [];
            let total_earnings = [];
            let years = [];
            
            

            
            if(data_dashboard_admin[7].length > 0){

              console.log(data_dashboard_admin[8].length+"{}{}");

            years.push("Select Year of Earnings");
            data_dashboard_admin[7].forEach(element => {
                   years.push(element["total_year"]);
            });

            setyear(years);
          }else{

            setyear(years);
          }

          if(data_dashboard_admin[8].length > 0){

            console.log(data_dashboard_admin[8].length+"{}{}");

            data_dashboard_admin[8].forEach(element => {
                months.push(months_label[element["month"]-1]);
                total_earnings.push(element["total_earnings"]);
            });
        
            setMonth(months);
            setTotalEarnings(total_earnings);

          }else{

            setMonth([0]);
            setTotalEarnings([0])

          }


            console.log(data_dashboard_admin[9][0]["range_18_25"]);

            
            const data_age_group = [
                { label: 'Age 18-25', value: data_dashboard_admin[9][0]["range_18_25"] , color: '#0088FE' },
                { label: 'Age 26-30', value: data_dashboard_admin[9][0]["range_25_30"] , color: '#00C49F'},
                { label: 'Age 31-35', value: data_dashboard_admin[9][0]["range_30_35"] ,color: '#FFBB28'},
                { label: 'Age 36-40', value: data_dashboard_admin[9][0]["range_35_40"] , color: '#FF8042'},
                { label: 'Age 41-45', value: data_dashboard_admin[9][0]["range_41_45"] , color: '#333'},
                { label: 'Age 46-50', value: data_dashboard_admin[9][0]["range_46_50"] , color: '#d3d3d3'}
              ];

            
              const TOTAL = data_age_group.map((item) => item.value).reduce((a, b) => a + b, 0);
              
              const getArcLabel = (params) => {
                const percent = params.value / TOTAL;
                return `${(percent * 100).toFixed(0)}%`;
              };

              setgetArcLabel(getArcLabel);
              setDataagegroup(data_age_group);
             
              const init_gender = [
                { label: 'Male', value: data_dashboard_admin[5][0]["male_total"] , color: '#0088FE' },
                { label: 'Female', value:  data_dashboard_admin[6][0]["female_total"] , color: '#00C49F'},
            ];
              

            setInitGender(init_gender);


            const init_members = [
                { label: 'Total Members', value: data_dashboard_admin[0][0]["total_members"] , color: '#DAE9F0' },
                { label: 'Premium Members', value: data_dashboard_admin[1][0]["premium_members"] , color: '#E3F498'},
                { label: 'Free Members', value: data_dashboard_admin[2][0]["free_members"] , color: '#DCD2ED' },
                { label: 'Blocked Members', value:  data_dashboard_admin[3][0]["blocked_members"] , color: '#FFD3C6'}
            ];

            setMembers(init_members);

            

              //setAgeGroup(data_age_group);

        
           
        }else{

          let communityId_admin  =  "0";
          if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

            communityId_admin = localStorage.getItem("communityId_admin");
            setCommunityId(communityId_admin);

          }else{
           communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
            setCommunityId(communityId_admin);

          }
            
          

        setTimeout(() => {

       const d = new Date();
       const year = d.getFullYear();

        const input = {
                "communityId":communityId_admin,
                "year":year,
                "offset":"0"
        };

        dashboard(input);
      }, 200);


        }

    },[data_dashboard_admin]);

    const handleClickCommunity = () => {
      setopenCommunity(true);
    }


    const handleCloseCommunity = (isopen) => {
      setopenCommunity(isopen);
    }


    const handleClickCommunityLink = (communityId_admin) => {

      setCommunityId(communityId_admin);

      localStorage.setItem("communityId_admin" , communityId_admin);

      

      setopenCommunity(false);
      const d = new Date();
      const year = d.getFullYear();

      const input = {
        "communityId":communityId_admin,
        "year":year,
        "offset":"0"
      };

        dashboard(input);
   
    }



    const handlePageChange = (event, value) => {
    
      setCurrentPage(value);
      const d = new Date();
      const year = d.getFullYear();

      console.log(value+"{}{}");

      const input = {
        "communityId":communityId,
        "year":year,
         "offset": value == 1 ? 0 : (((value - 2) * 10) + 10)
      };
          
      dashboard(input);

  }



    let role = localStorage.getItem(ROLE);

    return (<>
    
       {  load == false ?  <RotatingHeart/> : 
       
       <div>

        { role == "developer" ? <StylishLabel  text={"Select Community"}  clickSelectCommunity={handleClickCommunity} /> : ""}

       <Grid container spacing={2} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "165px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingRight:"15px" , backgroundColor:"#F5F5F5"  }}>

      {items.map((item, index) => (
        <Grid key={index} item xs={3} spacing={2}>
          <Paper style={{ backgroundColor: item.color, padding: 20, borderRadius: 10 }}>
            <Typography variant="h6" gutterBottom align="center">
              {item.title}
            </Typography>
            <Typography variant="body1" align="center">{counts[index]}</Typography>
          </Paper>
        </Grid>
      ))}

    <Grid key={0} item xs={6} spacing={0}>


    <FormControl fullWidth>
        <Select
          labelId="dropdown-label"
          id="dropdown"
          value={selectedValue}
          onChange={handleChange}
        >
          {years_option.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    <BarChart
      width={600}
      height={300}
      series={[
        { data: TotalEarnings, label: 'Total Earnigs Monthwise', id: 'pvId' },
      ]}
      xAxis={[{ data:month, scaleType: 'band' }]}
    />

    </Grid>


    <Grid key={1} item xs={6} spacing={0} style={{marginTop:"10px"}}>

    <Typography variant="h8"  align="right" alignItems={"end"} style={{marginLeft:"100px" , marginBottom:"10px"}}>
             {"Age Group Wise Married People"}
            </Typography>

    <PieChart
      series={[
        {
         data
        },
      ]}
      width={400}
      height={200}
    />


    </Grid>
    
    
    

    <Grid key={1} item xs={6} spacing={0}>

    <Typography variant="h8"  align="right" alignItems={"end"} style={{marginLeft:"100px" , marginBottom:"10px"}}>
             {"Male Vs Female"}
            </Typography>

    <PieChart
      series={[
        {
         data:data_gender
        },
      ]}
      width={400}
      height={200}
    />


    </Grid>



    <Grid key={1} item xs={6} spacing={0}>

<Typography variant="h8"  align="right" alignItems={"end"} style={{marginLeft:"120px" , marginBottom:"10px"}}>
         {"Members Type Numberwise"}
        </Typography>

<PieChart
  series={[
    {
     data:data_members
    },
  ]}
  width={500}
  height={200}
/>


</Grid>

    <Grid key={6} item xs={12} spacing={0} style={{marginBottom:"20px"}}>



    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <Typography  variant="h5" align="left" width="200px" style={{paddingLeft:"20px"}}>Happy Stories</Typography>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>Couple Name</TableCell>
            <TableCell>Marriage Date</TableCell>
            <TableCell>Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data_dashboard_admin[4].map((couple, index) => (
            <TableRow key={couple.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
              <TableCell>
                <img src={couple.image} alt="Couple" style={{ width: 50, height: 50 }} />
              </TableCell>
              <TableCell>{couple.husband_name+" & "+couple.wife_name}</TableCell>
              <TableCell>{couple.marriage_date}</TableCell>
              <TableCell>{couple.details}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>


    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
                <Pagination
                    count={data_dashboard_admin.length > 0 && Math.ceil(data_dashboard_admin[10][0]["total_row_count"] / 10)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="secondary"
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    shape="rounded"
                    size="large"
                />
            </div>


    </Grid>
   

    </Grid>






    {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}

    </div>
       
       }
    
    
    
    </>);


}

export default Dashboard;