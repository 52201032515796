const reducerMasterpage = (state, action) => {
    switch (action.type) {

      case 'RELIGION_FETCH':
        return {
          ...state,
          list_religion_admin: action.payload,
          loading_masterpage: false,
          error: null,
        };

      case 'RELIGION_FETCH_ERROR':
        return {
          ...state,
          list_religion_admin: [],
          loading_masterpage: false,
          error: action.payload,
        };

        case 'RELIGION_INSERT':
        return {
          ...state,
          data_insert_religion: action.payload,
          loading_masterpage: false,
          error: null,
        };

      case 'RELIGION_INSERT_ERROR':
        return {
          ...state,
          data_insert_religion: [],
          loading_masterpage: false,
          error: action.payload,
        };
        
        case 'RELIGION_UPDATE':
            return {
              ...state,
              data_update_religion: action.payload,
              loading_masterpage: false,
              error: null,
            };
            
          case 'RELIGION_UPDATE_ERROR':
            return {
              ...state,
              data_update_religion: [],
              loading_masterpage: false,
              error: action.payload,
            };


            case 'CASTE_FETCH':
        return {
          ...state,
          list_caste_admin: action.payload,
          loading_masterpage: false,
          error: null,
        };

      case 'CASTE_FETCH_ERROR':
        return {
          ...state,
          list_caste_admin: [],
          loading_masterpage: false,
          error: action.payload,
        };




        case 'CASTE_FETCH_DROPDOWN':
        return {
          ...state,
          list_castedropdown_admin: action.payload,
          loading_masterpage: false,
          error: null,
        };

      case 'CASTE_FETCH_DROPDOWN_ERROR':
        return {
          ...state,
          list_castedropdown_admin: [],
          loading_masterpage: false,
          error: action.payload,
        };



        case 'CASTE_INSERT':
      return {
        ...state,
        data_insert_caste: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'CASTE_INSERT_ERROR':
      return {
        ...state,
        data_insert_caste: [],
        loading_masterpage: false,
        error: action.payload,
      };
    case 'CASTE_UPDATE':
      return {
        ...state,
        data_update_caste: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'CASTE_UPDATE_ERROR':
      return {
        ...state,
        data_update_caste: [],
        loading_masterpage: false,
        error: action.payload,
      };






      case 'SUBCASTE_FETCH':
      return {
        ...state,
        list_subcaste_admin: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'SUBCASTE_FETCH_ERROR':
      return {
        ...state,
        list_subcaste_admin: [],
        loading_masterpage: false,
        error: action.payload,
      };
    case 'SUBCASTE_INSERT':
      return {
        ...state,
        data_insert_subcaste: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'SUBCASTE_INSERT_ERROR':
      return {
        ...state,
        data_insert_subcaste: [],
        loading_masterpage: false,
        error: action.payload,
      };
    case 'SUBCASTE_UPDATE':
      return {
        ...state,
        data_update_subcaste: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'SUBCASTE_UPDATE_ERROR':
      return {
        ...state,
        data_update_subcaste: [],
        loading_masterpage: false,
        error: action.payload,
      };






      case 'LANGUAGE_FETCH':
        return {
          ...state,
          list_language_admin: action.payload,
          loading_masterpage: false,
          error: null,
        };
      case 'LANGUAGE_FETCH_ERROR':
        return {
          ...state,
          list_language_admin: [],
          loading_masterpage: false,
          error: action.payload,
        };
      case 'LANGUAGE_INSERT':
        return {
          ...state,
          data_insert_language: action.payload,
          loading_masterpage: false,
          error: null,
        };
      case 'LANGUAGE_INSERT_ERROR':
        return {
          ...state,
          data_insert_language: [],
          loading_masterpage: false,
          error: action.payload,
        };
      case 'LANGUAGE_UPDATE':
        return {
          ...state,
          data_update_language: action.payload,
          loading_masterpage: false,
          error: null,
        };
      case 'LANGUAGE_UPDATE_ERROR':
        return {
          ...state,
          data_update_language: [],
          loading_masterpage: false,
          error: action.payload,
        };





        case 'COUNTRY_FETCH':
      return {
        ...state,
        list_country_admin: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'COUNTRY_FETCH_ERROR':
      return {
        ...state,
        list_country_admin: [],
        loading_masterpage: false,
        error: action.payload,
      };
    case 'COUNTRY_INSERT':
      return {
        ...state,
        data_insert_country: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'COUNTRY_INSERT_ERROR':
      return {
        ...state,
        data_insert_country: [],
        loading_masterpage: false,
        error: action.payload,
      };
    case 'COUNTRY_UPDATE':
      return {
        ...state,
        data_update_country: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'COUNTRY_UPDATE_ERROR':
      return {
        ...state,
        data_update_country: [],
        loading_masterpage: false,
        error: action.payload,
      };



      case 'CLEAR_STATE_LIST':
        return {
          ...state,
          list_state_admin: [],
          loading_masterpage: false,
          error: null,
        };


      case 'STATE_FETCH':
        return {
          ...state,
          list_state_admin: action.payload,
          loading_masterpage: false,
          error: null,
        };
      case 'STATE_FETCH_ERROR':
        return {
          ...state,
          list_state_admin: [],
          loading_masterpage: false,
          error: action.payload,
        };
      case 'STATE_INSERT':
        return {
          ...state,
          data_insert_state: action.payload,
          loading_masterpage: false,
          error: null,
        };
      case 'STATE_INSERT_ERROR':
        return {
          ...state,
          data_insert_state: [],
          loading_masterpage: false,
          error: action.payload,
        };
      case 'STATE_UPDATE':
        return {
          ...state,
          data_update_state: action.payload,
          loading_masterpage: false,
          error: null,
        };
      case 'STATE_UPDATE_ERROR':
        return {
          ...state,
          data_update_state: [],
          loading_masterpage: false,
          error: action.payload,
        };




        case 'CITY_FETCH':
      return {
        ...state,
        list_city_admin: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'CITY_FETCH_ERROR':
      return {
        ...state,
        list_city_admin: [],
        loading_masterpage: false,
        error: action.payload,
      };

    case 'CITY_INSERT':
      return {
        ...state,
        data_insert_city: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'CITY_INSERT_ERROR':
      return {
        ...state,
        data_insert_city: [],
        loading_masterpage: false,
        error: action.payload,
      };

    case 'CITY_UPDATE':
      return {
        ...state,
        data_update_city: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'CITY_UPDATE_ERROR':
      return {
        ...state,
        data_update_city: [],
        loading_masterpage: false,
        error: action.payload,
      };




      case 'DIETTYPE_FETCH':
        return {
          ...state,
          list_diet_fetch: action.payload,
          loading_masterpage: false,
          error: null,
        };
      case 'DIETTYPE_FETCH_ERROR':
        return {
          ...state,
          list_diet_fetch: [],
          loading_masterpage: false,
          error: action.payload,
        };
  
      case 'DIETTYPE_INSERT':
        return {
          ...state,
          data_insert_diet: action.payload,
          loading_masterpage: false,
          error: null,
        };
      case 'DIETTYPE_INSERT_ERROR':
        return {
          ...state,
          data_insert_diet: [],
          loading_masterpage: false,
          error: action.payload,
        };
  
      case 'DIETTYPE_UPDATE':
        return {
          ...state,
          data_update_diet: action.payload,
          loading_masterpage: false,
          error: null,
        };
      case 'DIETTYPE_UPDATE_ERROR':
        return {
          ...state,
          data_update_diet: [],
          loading_masterpage: false,
          error: action.payload,
        };


        case 'DIETTYPE_DELETE':
        return {
          ...state,
          data_delete_diet: action.payload,
          loading_masterpage: false,
          error: null,
        };
      case 'DIETTYPE_DELETE_ERROR':
        return {
          ...state,
          data_delete_diet: [],
          loading_masterpage: false,
          error: action.payload,
        };




      case 'CREATEDBY_FETCH':
      return {
        ...state,
        list_createdby_admin: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'CREATEDBY_FETCH_ERROR':
      return {
        ...state,
        list_createdby_admin: [],
        loading_masterpage: false,
        error: action.payload,
      };

    case 'CREATEDBY_INSERT':
      return {
        ...state,
        data_insert_createdby: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'CREATEDBY_INSERT_ERROR':
      return {
        ...state,
        data_insert_createdby: [],
        loading_masterpage: false,
        error: action.payload,
      };

    case 'CREATEDBY_UPDATE':
      return {
        ...state,
        data_update_createdby: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'CREATEDBY_UPDATE_ERROR':
      return {
        ...state,
        data_update_createdby: [],
        loading_masterpage: false,
        error: action.payload,
      };
   




      case 'FAMILYSTATYUS_FETCH':
      return {
        ...state,
        list_familystatus_admin: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'FAMILYSTATYUS_FETCH_ERROR':
      return {
        ...state,
        list_familystatus_admin: [],
        loading_masterpage: false,
        error: action.payload,
      };
    case 'FAMILYSTATYUS_INSERT':
      return {
        ...state,
        data_insert_familystatus: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'FAMILYSTATYUS_INSERT_ERROR':
      return {
        ...state,
        data_insert_familystatus: [],
        loading_masterpage: false,
        error: action.payload,
      };
    case 'FAMILYSTATYUS_UPDATE':
      return {
        ...state,
        data_update_familystatus: action.payload,
        loading_masterpage: false,
        error: null,
      };
    case 'FAMILYSTATYUS_UPDATE_ERROR':
      return {
        ...state,
        data_update_familystatus: [],
        loading_masterpage: false,
        error: action.payload,
      };




      case 'FAMILYVALUES_FETCH':
        return {
          ...state,
          list_familyvalue_admin: action.payload,
          loading_masterpage: false,
          error: null,
        };

      case 'FAMILYVALUES_FETCH_ERROR':
        return {
          ...state,
          list_familyvalue_admin: [],
          loading_masterpage: false,
          error: action.payload,
        };

      case 'FAMILYVALUES_INSERT':
        return {
          ...state,
          data_insert_familyvalue: action.payload,
          loading_masterpage: false,
          error: null,
        };

      case 'FAMILYVALUES_INSERT_ERROR':
        return {
          ...state,
          data_insert_familyvalue: [],
          loading_masterpage: false,
          error: action.payload,
        };

      case 'FAMILYVALUES_UPDATE':
        return {
          ...state,
          data_update_familyvalue: action.payload,
          loading_masterpage: false,
          error: null,
        };

      case 'FAMILYVALUES_UPDATE_ERROR':
        return {
          ...state,
          data_update_familyvalue: [],
          loading_masterpage: false,
          error: action.payload,
        };

        case 'CLEAR_LIST_CASTE':
          return{
          ...state,
          list_caste_admin:[]
          };
        
      default:
        return state;
    }
  };

  export default reducerMasterpage;